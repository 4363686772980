import '@/styles/globals.css';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { useApollo, useArticleApollo } from '@/api/apolloClient';
import { CookieBarSection } from '@/components/cookies/CookieBarSection';
import { CookieSettingSection } from '@/components/cookies/CookieSettingSection';
import { AnalyticsProvider } from '@/components/providers/AnalyticsProvider';

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps);
  const articleClient = useArticleApollo(pageProps);

  return (
    <ApolloProvider client={articleClient}>
      <ApolloProvider client={client}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Collapo</title>
        </Head>
        <AnalyticsProvider>
          <Component {...pageProps} />
          <CookieBarSection key="cookies-bar" />
          <CookieSettingSection key="cookies-settings" />
        </AnalyticsProvider>
      </ApolloProvider>
    </ApolloProvider>
  );
};

export default App;
