import clsx from 'clsx';
import { useState } from 'react';

import Button from '@/components/Button/Button';
import ButtonLink from '@/components/ButtonLink/ButtonLink';
import RichText, { RichTextProps } from '@/components/shared/RichText';
import { ButtonLinkType } from '@/types/buttonType';
import { useGlobalType } from '@/types/useGlobalType';

interface CookieBarButton {
  label: string;
  onClick: () => void;
  useType?: useGlobalType;
}

export interface CookiesBarToggleTextProps {
  firstText: string;
  secondText: string;
}

export interface CookiesBarProps {
  heading: string;
  text: RichTextProps[];
  extraLinks: ButtonLinkType[];
  editButton: ButtonLinkType;
  declineButton: CookieBarButton;
  acceptButton: CookieBarButton;
  toggleText: CookiesBarToggleTextProps;
}

const CookiesBar = ({
  heading,
  text,
  editButton,
  declineButton,
  acceptButton,
  extraLinks,
  toggleText,
}: CookiesBarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="flex w-full justify-center bg-collapo-black-20">
      <div className="flex w-full max-w-desktop flex-col gap-5 px-4 py-8 md:px-10">
        <div className="space-y-3">
          <p className="text-h4 text-collapo-black">{heading}</p>
          <div
            className={clsx(
              'max-h-[160px] sm:max-h-max',
              isExpanded
                ? 'line-clamp-none overflow-y-auto'
                : 'line-clamp-3 sm:line-clamp-none',
            )}
          >
            <RichText
              isCookieBar
              items={text}
              parentSection="cookiesBar"
              parentIndex={1}
            />
          </div>
          <span
            onClick={toggleExpand}
            className="text-p-small text-collapo-blue hover:text-collapo-blue-75 sm:hidden"
          >
            {isExpanded ? toggleText.secondText : toggleText.firstText}
          </span>
        </div>
        <div className="flex w-full flex-wrap items-center justify-between gap-x-4 gap-y-8">
          <div className="flex gap-6">
            {extraLinks.map((link) => (
              <ButtonLink
                className="max-w-max text-p-small"
                key={link.label}
                href={link.path}
                isTextLink
              >
                {link.label}
              </ButtonLink>
            ))}
          </div>
          <div className="flex w-full flex-wrap justify-center gap-x-4 gap-y-3 sm:w-auto sm:justify-normal">
            <ButtonLink
              href={editButton.path}
              className="sm:max-w-max"
              useType={editButton.useType}
            >
              {editButton.label}
            </ButtonLink>
            <Button
              onClick={declineButton.onClick}
              className="sm:max-w-max"
              useType={declineButton.useType}
            >
              {declineButton.label}
            </Button>
            <Button
              onClick={acceptButton.onClick}
              className="sm:max-w-max"
              useType={acceptButton.useType}
            >
              {acceptButton.label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesBar;
