import { CookiesTableSettingItemType } from '@/components/CookiesSettings/CookiesSettings';
import TableTextContent from '@/components/PrivacyPolicy/TableTextContent';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';

export const CookiesTableSettingItem = ({
  heading,
  toggleSwitch,
  tableContent,
}: CookiesTableSettingItemType) => {
  return (
    <div className="space-y-5">
      <div className="flex flex-wrap justify-between gap-4">
        <span className="text-h4 text-collapo-black">{heading}</span>
        <ToggleSwitch {...toggleSwitch} />
      </div>
      <TableTextContent {...tableContent} isInModal />
    </div>
  );
};
