import { useMemo } from 'react';

import Button, { ButtonProps } from '@/components/Button/Button';
import ButtonLink from '@/components/ButtonLink/ButtonLink';
import { CookiesSettingsHeader } from '@/components/CookiesSettings/CookiesSettingsHeader';
import { CookiesTableSettingItem } from '@/components/CookiesSettings/CookiesTableSettingItem';
import { TableTextContentProps } from '@/components/PrivacyPolicy/PrivacyPolicy.types';
import Modal from '@/components/shared/Modal/Modal';
import { ToggleSwitchProps } from '@/components/ToggleSwitch/ToggleSwitch';
import { ButtonLinkType } from '@/types/buttonType';

export type CookiesTableSettingItemType = {
  heading: string;
  toggleSwitch: ToggleSwitchProps;
  tableContent: TableTextContentProps;
};

export interface CookiesSettingsHeaderProps {
  heading: string;
  closeModal: () => void;
}

export interface CookiesSettingsProps {
  heading: string;
  tableContentItems: CookiesTableSettingItemType[];
  actionButton: ButtonProps;
  moreInfoLink: ButtonLinkType;
  isModalOpen: boolean;
  closeModal: () => void;
}

const CookiesSettings = ({
  heading,
  tableContentItems,
  actionButton,
  moreInfoLink,
  isModalOpen,
  closeModal,
}: CookiesSettingsProps) => {
  const memoTableContentItems = useMemo(() => {
    return tableContentItems?.map((tableContentItem) => ({
      ...tableContentItem,
    }));
  }, [tableContentItems]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div className="max-w-[1000px] bg-collapo-white px-4 md:px-10">
        <div className="space-y-6">
          <CookiesSettingsHeader heading={heading} closeModal={closeModal} />
          <div className="space-y-8">
            {memoTableContentItems.map(({ ...tableContentItem }, index) => (
              <CookiesTableSettingItem
                key={`memoTableContentItems-cookieSettings-${index}`}
                {...tableContentItem}
              />
            ))}
          </div>
          <div className="flex flex-col items-center justify-center gap-y-6 pb-6 sm:flex-row sm:justify-between">
            <ButtonLink
              className="max-w-max text-h6 uppercase"
              href={moreInfoLink.path}
              aria-label={moreInfoLink.label}
              isTextLink
            >
              {moreInfoLink.label}
            </ButtonLink>
            <Button
              onClick={actionButton.onClick}
              aria-label={actionButton.label}
              className="xs:max-w-[272px]"
            >
              {actionButton.label}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CookiesSettings;
