import { useEffect } from 'react';

import { useScrollbarWidth } from '@/hooks/useScrollbarWidth';

interface Props {
  isOpen: boolean;
}

export function useToggleBodyClasses({ isOpen }: Props) {
  const scrollbarWidth = useScrollbarWidth();

  useEffect(() => {
    const body = document.querySelector('body');

    if (isOpen) {
      body?.classList.add('h-svh', 'overflow-hidden');
      if (body) {
        body.style.marginRight = `${scrollbarWidth}px`;
        body.style.overflowY = 'hidden'; // Explicitly setting overflow-y
      }
    } else {
      body?.classList.remove('h-svh', 'overflow-hidden');
      if (body) {
        body.style.marginRight = '0px';
        body.style.overflowY = ''; // Resetting overflow-y to default
      }
    }

    // Cleanup when the component unmounts or `isOpen` changes
    return () => {
      body?.classList.remove('h-svh', 'overflow-hidden');
      if (body) {
        body.style.marginRight = '0px';
        body.style.overflowY = ''; // Resetting overflow-y to default
      }
    };
  }, [isOpen, scrollbarWidth]);
}
