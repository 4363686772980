import { getCookie, hasCookie } from 'cookies-next';
import { ReactNode, useState } from 'react';

import GoogleAnalyticsProvider from './GoogleAnalyticsProvider';

type Props = {
  children: ReactNode;
};

export const AnalyticsProvider = ({ children }: Props) => {
  const [all, setAll] = useState(
    hasCookie('collapo_cookie_consent_optional') &&
      getCookie('collapo_cookie_consent_optional') === 'true',
  );

  if (all)
    return (
      <>
        <GoogleAnalyticsProvider />
        {children}
      </>
    );

  return <>{children}</>;
};
