import clsx from 'clsx';
import { useMemo } from 'react';

import { TableTextContentProps } from '@/components/PrivacyPolicy/PrivacyPolicy.types';
import RichText from '@/components/shared/RichText';

const TableTextContent = ({
  text,
  tableData,
  isInModal,
  parentIndex,
}: TableTextContentProps) => {
  const memoTableData = useMemo(() => {
    return tableData.values.map((item) => ({
      item,
    }));
  }, [tableData]);

  return (
    <div className="space-y-9">
      {typeof text === 'string' ? (
        <p className={clsx(isInModal ? 'text-p-small' : 'text-p')}>{text}</p>
      ) : (
        <RichText
          items={text}
          parentSection="tableTextContent"
          parentIndex={parentIndex}
        />
      )}
      <div
        className={clsx(
          'w-full overflow-x-auto',
          isInModal ? 'xl:w-fit' : 'xl:min-w-[1136px]',
        )}
      >
        <div
          className={clsx(
            'flex w-full gap-4 uppercase lg:gap-5',
            isInModal ? 'min-w-[820px] xl:min-w-[920px]' : 'min-w-[786px]',
          )}
        >
          {tableData.headings.map((heading, index) => (
            <div
              className="flex-1 pb-[6px] text-h7 last:text-right"
              key={`tableData-tableTextContent-${parentIndex}-${index}`}
            >
              {heading}
            </div>
          ))}
        </div>
        {memoTableData.map((item, tableDataIndex) => (
          <div
            key={`memoTableData-tableTextContent-${parentIndex}-${tableDataIndex}`}
            className={clsx(
              'flex w-full gap-4 border-t border-collapo-black-40 pb-[9px] xl:gap-5',
              isInModal ? 'min-w-[820px] xl:min-w-[920px]' : 'min-w-[786px]',
              tableDataIndex === 0 ? 'pt-[13px]' : 'pt-[10px]',
            )}
          >
            {item.item.map((cell, index) => (
              <div
                className="flex-1 text-p-small first:break-all last:w-[85px] last:text-right"
                key={`memoTableData-tableTextContent-${parentIndex}-${tableDataIndex}-${index}`}
              >
                {cell}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableTextContent;
