import clsx from 'clsx';
import { useState } from 'react';

export interface ToggleSwitchProps {
  initialValue: boolean;
  onChange: (value: boolean) => void;
  isFixed?: boolean;
}

const ToggleSwitch = ({
  initialValue,
  onChange,
  isFixed: isFixed,
}: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(isFixed ? true : initialValue);
  const [label, setLabel] = useState(
    isFixed ? 'Povolené' : initialValue ? 'Povolené' : 'Zakázáné',
  );
  const handleToggle = () => {
    if (isFixed) return;
    const newValue = !isChecked;
    setIsChecked(newValue);
    setLabel(newValue ? 'Povolené' : 'Zakázáné');
    onChange(newValue);
  };

  return (
    <div className="group flex items-center gap-x-3">
      <p
        className={clsx(
          'text-p-small text-collapo-black',
          isFixed && 'opacity-50',
        )}
      >
        {label}
      </p>
      <label className={clsx(isFixed ? 'cursor-default' : 'cursor-pointer')}>
        <div className="relative">
          <input
            type="checkbox"
            className="hidden"
            checked={isChecked}
            onChange={handleToggle}
          />
          <div
            className={clsx(
              'flex h-5 w-[42px] items-center rounded-fully border-[1.1px] border-solid border-collapo-blue p-[3px] duration-300',
              isChecked &&
                'bg-collapo-blue group-hover:border-collapo-blue-75 group-hover:bg-collapo-blue-75',
              isFixed && 'opacity-50',
            )}
          >
            <div
              className={clsx(
                'absolute size-[14px] rounded-fully duration-300',
                isChecked
                  ? 'translate-x-[22px] bg-collapo-white'
                  : 'translate-x-0',
                !isChecked &&
                  'bg-collapo-blue group-hover:border-collapo-blue-75 group-hover:bg-collapo-blue-75',
              )}
            />
          </div>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
