import clsx from 'clsx';
import { ReactNode } from 'react';

import { useCloseByEsc } from '@/hooks/useCloseByEsc';
import { useToggleBodyClasses } from '@/hooks/useToggleBodyClasses';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  useToggleBodyClasses({ isOpen });
  useCloseByEsc({ handleClose: onClose });

  return (
    <div
      className={clsx(
        'fixed inset-0 z-[100] flex size-full items-center justify-center',
        isOpen ? 'visible bg-black/70' : 'invisible',
      )}
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-h-dvh overflow-y-auto lg:max-h-[calc(100dvh-100px)]"
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
