import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CompanyContentDynamicZoneInput: { input: any; output: any };
  CookiePolicyCookiePolicyDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  HomeContentDynamicZoneInput: { input: any; output: any };
  I18NLocaleCode: { input: any; output: any };
  JSON: { input: any; output: any };
  ManufacturerListContentDynamicZoneInput: { input: any; output: any };
  ManufacturersAndPartnerContentDynamicZoneInput: { input: any; output: any };
  ProductAndServiceContentDynamicZoneInput: { input: any; output: any };
  ProductContentDynamicZoneInput: { input: any; output: any };
  ReferenceContentDynamicZoneInput: { input: any; output: any };
  ReferenceListContentDynamicZoneInput: { input: any; output: any };
  ServiceContentDynamicZoneInput: { input: any; output: any };
  SocialResponsibilityContentDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Company = {
  __typename?: 'Company';
  content?: Maybe<Array<Maybe<CompanyContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CompanyRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CompanyContentDynamicZone =
  | ComponentSharedOurTeam
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes?: Maybe<Company>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyInput = {
  content?: InputMaybe<
    Array<Scalars['CompanyContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyRelationResponseCollection = {
  __typename?: 'CompanyRelationResponseCollection';
  data: Array<CompanyEntity>;
};

export type ComponentFooterColumn = {
  __typename?: 'ComponentFooterColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentUiLink>>>;
};

export type ComponentFooterColumnLinkArgs = {
  filters?: InputMaybe<ComponentUiLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterColumnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentUiLinkFiltersInput>;
  not?: InputMaybe<ComponentFooterColumnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
};

export type ComponentFooterColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentUiLinkInput>>>;
};

export type ComponentFooterContact = {
  __typename?: 'ComponentFooterContact';
  email?: Maybe<ComponentUiLink>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<ComponentUiLink>;
};

export type ComponentFooterContactInput = {
  email?: InputMaybe<ComponentUiLinkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<ComponentUiLinkInput>;
};

export type ComponentFooterPolicies = {
  __typename?: 'ComponentFooterPolicies';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentUiLink>>>;
};

export type ComponentFooterPoliciesLinkArgs = {
  filters?: InputMaybe<ComponentUiLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterPoliciesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentUiLinkInput>>>;
};

export type ComponentNavigationMenuItem = {
  __typename?: 'ComponentNavigationMenuItem';
  id: Scalars['ID']['output'];
  isButton?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<ComponentUiLink>;
  subLink?: Maybe<Array<Maybe<ComponentUiLink>>>;
};

export type ComponentNavigationMenuItemSubLinkArgs = {
  filters?: InputMaybe<ComponentUiLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationMenuItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationMenuItemFiltersInput>>>;
  isButton?: InputMaybe<BooleanFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentUiLinkFiltersInput>;
  not?: InputMaybe<ComponentNavigationMenuItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationMenuItemFiltersInput>>>;
  subLink?: InputMaybe<ComponentUiLinkFiltersInput>;
};

export type ComponentNavigationMenuItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isButton?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<ComponentUiLinkInput>;
  subLink?: InputMaybe<Array<InputMaybe<ComponentUiLinkInput>>>;
};

export type ComponentPoliciesMainHeading = {
  __typename?: 'ComponentPoliciesMainHeading';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPoliciesRowItem = {
  __typename?: 'ComponentPoliciesRowItem';
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
  item?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
};

export type ComponentPoliciesRowItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesTableHeading = {
  __typename?: 'ComponentPoliciesTableHeading';
  headingItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesTableHeadingFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>
  >;
  headingItem?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingFiltersInput>>>;
};

export type ComponentPoliciesTableHeadingInput = {
  headingItem?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentPoliciesTableRow = {
  __typename?: 'ComponentPoliciesTableRow';
  id: Scalars['ID']['output'];
  table_rows?: Maybe<TableRowRelationResponseCollection>;
};

export type ComponentPoliciesTableRowTable_RowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableRowInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  table_rows?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentPoliciesTableTextContent = {
  __typename?: 'ComponentPoliciesTableTextContent';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHeading>>>;
  tableRow?: Maybe<ComponentPoliciesTableRow>;
};

export type ComponentPoliciesTableTextContentTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableTextContentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  tableHead?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadingInput>>>;
  tableRow?: InputMaybe<ComponentPoliciesTableRowInput>;
};

export type ComponentPoliciesTitleTextContent = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedHeroImageSection = {
  __typename?: 'ComponentSharedHeroImageSection';
  button?: Maybe<ComponentUiButton>;
  heroImage?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedHeroImageSectionFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedHeroImageSectionFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  heroImage?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentSharedHeroImageSectionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedHeroImageSectionFiltersInput>>
  >;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedHeroImageSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroImage?: InputMaybe<ComponentUiImageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedHeroVideoSection = {
  __typename?: 'ComponentSharedHeroVideoSection';
  button?: Maybe<ComponentUiButton>;
  heroVideo?: Maybe<ComponentUiVideo>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedHeroVideoSectionFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>>
  >;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  heroVideo?: InputMaybe<ComponentUiVideoFiltersInput>;
  not?: InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>>
  >;
  subtitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedHeroVideoSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroVideo?: InputMaybe<ComponentUiVideoInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedIconSpec = {
  __typename?: 'ComponentSharedIconSpec';
  icon?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedIconSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedIconSpecFiltersInput>>>;
  icon?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentSharedIconSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedIconSpecFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedImagesGridItem = {
  __typename?: 'ComponentSharedImagesGridItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedImagesGridItemFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedImagesGridItemFiltersInput>>
  >;
  description?: InputMaybe<StringFilterInput>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentSharedImagesGridItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedImagesGridItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedLargeImageSection = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLogoCarousel = {
  __typename?: 'ComponentSharedLogoCarousel';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  item?: Maybe<Array<Maybe<ComponentSharedLogoCarouselItem>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLogoCarouselItemArgs = {
  filters?: InputMaybe<ComponentSharedLogoCarouselItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedLogoCarouselItem = {
  __typename?: 'ComponentSharedLogoCarouselItem';
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  link?: Maybe<ComponentUiLink>;
};

export type ComponentSharedLogoCarouselItemFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedLogoCarouselItemFiltersInput>>
  >;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  link?: InputMaybe<ComponentUiLinkFiltersInput>;
  not?: InputMaybe<ComponentSharedLogoCarouselItemFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedLogoCarouselItemFiltersInput>>
  >;
};

export type ComponentSharedManufacturers = {
  __typename?: 'ComponentSharedManufacturers';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturer_lists?: Maybe<ManufacturerListRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedManufacturersManufacturer_ListsArgs = {
  filters?: InputMaybe<ManufacturerListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedManufacturersInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  manufacturer_lists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedOurTeam = {
  __typename?: 'ComponentSharedOurTeam';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<Array<Maybe<ComponentSharedOurTeamItem>>>;
  sectionContent?: Maybe<ComponentSharedSectionContent>;
};

export type ComponentSharedOurTeamItemArgs = {
  filters?: InputMaybe<ComponentSharedOurTeamItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedOurTeamItem = {
  __typename?: 'ComponentSharedOurTeamItem';
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedOurTeamItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedOurTeamItemFiltersInput>>>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedOurTeamItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedOurTeamItemFiltersInput>>>;
  position?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedPreview = {
  __typename?: 'ComponentSharedPreview';
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedPreviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedPreviewFiltersInput>>>;
  button?: InputMaybe<ComponentUiButtonFiltersInput>;
  description?: InputMaybe<StringFilterInput>;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentSharedPreviewFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedPreviewFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedPreviewInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedProducts = {
  __typename?: 'ComponentSharedProducts';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  products?: Maybe<ProductRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedProductsProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedProductsInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedReferences = {
  __typename?: 'ComponentSharedReferences';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reference_lists?: Maybe<ReferenceListRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedReferencesReference_ListsArgs = {
  filters?: InputMaybe<ReferenceListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedReferencesInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  reference_lists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSectionContent = {
  __typename?: 'ComponentSharedSectionContent';
  content?: Maybe<ComponentUiTextContent>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  metaViewport?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: InputMaybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentSharedTextImageSection = {
  __typename?: 'ComponentSharedTextImageSection';
  IconSpec?: Maybe<Array<Maybe<ComponentSharedIconSpec>>>;
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTextImageSectionIconSpecArgs = {
  filters?: InputMaybe<ComponentSharedIconSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTextItem = {
  __typename?: 'ComponentSharedTextItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTextItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedTextItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedTextItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedTextItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedToggleText = {
  __typename?: 'ComponentSharedToggleText';
  firstText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  secondText?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedToggleTextInput = {
  firstText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  secondText?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedTwoImagesGrid = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imagesGridItems?: Maybe<Array<Maybe<ComponentSharedImagesGridItem>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTwoImagesGridImagesGridItemsArgs = {
  filters?: InputMaybe<ComponentSharedImagesGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTwoVideosGrid = {
  __typename?: 'ComponentSharedTwoVideosGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  videosGridItems?: Maybe<Array<Maybe<ComponentSharedVideosGridItem>>>;
};

export type ComponentSharedTwoVideosGridVideosGridItemsArgs = {
  filters?: InputMaybe<ComponentSharedVideosGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedVideosGridItem = {
  __typename?: 'ComponentSharedVideosGridItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<ComponentUiVideo>;
};

export type ComponentSharedVideosGridItemFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedVideosGridItemFiltersInput>>
  >;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedVideosGridItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedVideosGridItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  video?: InputMaybe<ComponentUiVideoFiltersInput>;
};

export type ComponentSharedWorkflow = {
  __typename?: 'ComponentSharedWorkflow';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  item?: Maybe<Array<Maybe<ComponentSharedTextItem>>>;
  sectionContent?: Maybe<ComponentSharedSectionContent>;
};

export type ComponentSharedWorkflowItemArgs = {
  filters?: InputMaybe<ComponentSharedTextItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiButton = {
  __typename?: 'ComponentUiButton';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiButtonFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiButtonInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiImage = {
  __typename?: 'ComponentUiImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img_desktop: UploadFileEntityResponse;
  img_mobile?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
  not?: InputMaybe<ComponentUiImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
};

export type ComponentUiImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiLink = {
  __typename?: 'ComponentUiLink';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
};

export type ComponentUiLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTextContent = {
  __typename?: 'ComponentUiTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentUiVideo = {
  __typename?: 'ComponentUiVideo';
  id: Scalars['ID']['output'];
  mobilePoster?: Maybe<UploadFileEntityResponse>;
  poster: UploadFileEntityResponse;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiVideoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiVideoFiltersInput>>>;
  not?: InputMaybe<ComponentUiVideoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiVideoFiltersInput>>>;
  poster_alt?: InputMaybe<StringFilterInput>;
};

export type ComponentUiVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mobilePoster?: InputMaybe<Scalars['ID']['input']>;
  poster?: InputMaybe<Scalars['ID']['input']>;
  poster_alt?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['ID']['input']>;
};

export type ContactFomResponse = {
  __typename?: 'ContactFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFormDataInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  terms: Scalars['Boolean']['input'];
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  cookiePolicy?: Maybe<Array<Maybe<CookiePolicyCookiePolicyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookiePolicyRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiePolicyLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookiePolicyCookiePolicyDynamicZone =
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CookiePolicyEntity = {
  __typename?: 'CookiePolicyEntity';
  attributes?: Maybe<CookiePolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiePolicyEntityResponse = {
  __typename?: 'CookiePolicyEntityResponse';
  data?: Maybe<CookiePolicyEntity>;
};

export type CookiePolicyInput = {
  cookiePolicy?: InputMaybe<
    Array<Scalars['CookiePolicyCookiePolicyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type CookiePolicyRelationResponseCollection = {
  __typename?: 'CookiePolicyRelationResponseCollection';
  data: Array<CookiePolicyEntity>;
};

export type CookieSetting = {
  __typename?: 'CookieSetting';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookieSettingRelationResponseCollection>;
  necessaryCookiesHeading?: Maybe<Scalars['String']['output']>;
  necessaryCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  optionalCookiesHeading?: Maybe<Scalars['String']['output']>;
  optionalCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  redirectButton?: Maybe<ComponentUiButton>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookieSettingLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookieSettingEntity = {
  __typename?: 'CookieSettingEntity';
  attributes?: Maybe<CookieSetting>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookieSettingEntityResponse = {
  __typename?: 'CookieSettingEntityResponse';
  data?: Maybe<CookieSettingEntity>;
};

export type CookieSettingInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  optionalCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  optionalCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  redirectButton?: InputMaybe<ComponentUiButtonInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CookieSettingRelationResponseCollection = {
  __typename?: 'CookieSettingRelationResponseCollection';
  data: Array<CookieSettingEntity>;
};

export type CookiesBanner = {
  __typename?: 'CookiesBanner';
  acceptButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  declineButtonLabel?: Maybe<Scalars['String']['output']>;
  editButtonLabel?: Maybe<Scalars['String']['output']>;
  extraLinks?: Maybe<Array<Maybe<ComponentUiButton>>>;
  heading?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookiesBannerRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  toggleText?: Maybe<ComponentSharedToggleText>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiesBannerExtraLinksArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CookiesBannerLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookiesBannerEntity = {
  __typename?: 'CookiesBannerEntity';
  attributes?: Maybe<CookiesBanner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiesBannerEntityResponse = {
  __typename?: 'CookiesBannerEntityResponse';
  data?: Maybe<CookiesBannerEntity>;
};

export type CookiesBannerInput = {
  acceptButtonLabel?: InputMaybe<Scalars['String']['input']>;
  declineButtonLabel?: InputMaybe<Scalars['String']['input']>;
  editButtonLabel?: InputMaybe<Scalars['String']['input']>;
  extraLinks?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  toggleText?: InputMaybe<ComponentSharedToggleTextInput>;
};

export type CookiesBannerRelationResponseCollection = {
  __typename?: 'CookiesBannerRelationResponseCollection';
  data: Array<CookiesBannerEntity>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Sitemapsitemap_Type {
  DefaultHreflang = 'default_hreflang',
  Index = 'index',
}

export enum Enum_Translatebatchtranslatejob_Status {
  Cancelled = 'cancelled',
  Created = 'created',
  Failed = 'failed',
  Finished = 'finished',
  Paused = 'paused',
  Running = 'running',
  Setup = 'setup',
}

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorResult = {
  __typename?: 'ErrorResult';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  column?: Maybe<Array<Maybe<ComponentFooterColumn>>>;
  contact?: Maybe<ComponentFooterContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<FooterRelationResponseCollection>;
  policies?: Maybe<ComponentFooterPolicies>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FooterColumnArgs = {
  filters?: InputMaybe<ComponentFooterColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type FooterEntity = {
  __typename?: 'FooterEntity';
  attributes?: Maybe<Footer>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterEntityResponse = {
  __typename?: 'FooterEntityResponse';
  data?: Maybe<FooterEntity>;
};

export type FooterInput = {
  column?: InputMaybe<Array<InputMaybe<ComponentFooterColumnInput>>>;
  contact?: InputMaybe<ComponentFooterContactInput>;
  policies?: InputMaybe<ComponentFooterPoliciesInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FooterRelationResponseCollection = {
  __typename?: 'FooterRelationResponseCollection';
  data: Array<FooterEntity>;
};

export type GenericMorph =
  | Company
  | ComponentFooterColumn
  | ComponentFooterContact
  | ComponentFooterPolicies
  | ComponentNavigationMenuItem
  | ComponentPoliciesMainHeading
  | ComponentPoliciesRowItem
  | ComponentPoliciesTableHeading
  | ComponentPoliciesTableRow
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | ComponentSharedHeroImageSection
  | ComponentSharedHeroVideoSection
  | ComponentSharedIconSpec
  | ComponentSharedImagesGridItem
  | ComponentSharedLargeImageSection
  | ComponentSharedLogoCarousel
  | ComponentSharedLogoCarouselItem
  | ComponentSharedManufacturers
  | ComponentSharedMetaSocial
  | ComponentSharedOurTeam
  | ComponentSharedOurTeamItem
  | ComponentSharedPreview
  | ComponentSharedProducts
  | ComponentSharedReferences
  | ComponentSharedSectionContent
  | ComponentSharedSeo
  | ComponentSharedTextImageSection
  | ComponentSharedTextItem
  | ComponentSharedToggleText
  | ComponentSharedTwoImagesGrid
  | ComponentSharedTwoVideosGrid
  | ComponentSharedVideosGridItem
  | ComponentSharedWorkflow
  | ComponentUiButton
  | ComponentUiImage
  | ComponentUiLink
  | ComponentUiTextContent
  | ComponentUiVideo
  | CookiePolicy
  | CookieSetting
  | CookiesBanner
  | Footer
  | GetInTouch
  | Home
  | I18NLocale
  | ManufacturerList
  | ManufacturersAndPartner
  | Navigation
  | Product
  | ProductAndService
  | Reference
  | ReferenceList
  | Service
  | SitemapSitemap
  | SitemapSitemapCache
  | SocialResponsibility
  | TableRow
  | TranslateBatchTranslateJob
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | WebexIntegration;

export type GetInTouch = {
  __typename?: 'GetInTouch';
  anchor?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  first_line?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<GetInTouchRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  second_line?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GetInTouchLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type GetInTouchEntity = {
  __typename?: 'GetInTouchEntity';
  attributes?: Maybe<GetInTouch>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GetInTouchEntityResponse = {
  __typename?: 'GetInTouchEntityResponse';
  data?: Maybe<GetInTouchEntity>;
};

export type GetInTouchInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  first_line?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  second_line?: InputMaybe<Scalars['String']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetInTouchRelationResponseCollection = {
  __typename?: 'GetInTouchRelationResponseCollection';
  data: Array<GetInTouchEntity>;
};

export type Home = {
  __typename?: 'Home';
  content?: Maybe<Array<Maybe<HomeContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<HomeRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type HomeLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type HomeContentDynamicZone =
  | ComponentSharedLogoCarousel
  | ComponentSharedWorkflow
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type HomeEntity = {
  __typename?: 'HomeEntity';
  attributes?: Maybe<Home>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HomeEntityResponse = {
  __typename?: 'HomeEntityResponse';
  data?: Maybe<HomeEntity>;
};

export type HomeInput = {
  content?: InputMaybe<Array<Scalars['HomeContentDynamicZoneInput']['input']>>;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomeRelationResponseCollection = {
  __typename?: 'HomeRelationResponseCollection';
  data: Array<HomeEntity>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type ManufacturerList = {
  __typename?: 'ManufacturerList';
  content?: Maybe<Array<Maybe<ManufacturerListContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ManufacturerListRelationResponseCollection>;
  preview?: Maybe<ComponentSharedPreview>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ManufacturerListLocalizationsArgs = {
  filters?: InputMaybe<ManufacturerListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ManufacturerListContentDynamicZone =
  | ComponentSharedLargeImageSection
  | ComponentSharedTextImageSection
  | ComponentSharedTwoImagesGrid
  | ComponentSharedTwoVideosGrid
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ManufacturerListEntity = {
  __typename?: 'ManufacturerListEntity';
  attributes?: Maybe<ManufacturerList>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ManufacturerListEntityResponse = {
  __typename?: 'ManufacturerListEntityResponse';
  data?: Maybe<ManufacturerListEntity>;
};

export type ManufacturerListEntityResponseCollection = {
  __typename?: 'ManufacturerListEntityResponseCollection';
  data: Array<ManufacturerListEntity>;
  meta: ResponseCollectionMeta;
};

export type ManufacturerListFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ManufacturerListFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionFiltersInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ManufacturerListFiltersInput>;
  not?: InputMaybe<ManufacturerListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ManufacturerListFiltersInput>>>;
  preview?: InputMaybe<ComponentSharedPreviewFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug_cs?: InputMaybe<StringFilterInput>;
  slug_de?: InputMaybe<StringFilterInput>;
  slug_en?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ManufacturerListInput = {
  content?: InputMaybe<
    Array<Scalars['ManufacturerListContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  preview?: InputMaybe<ComponentSharedPreviewInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ManufacturerListRelationResponseCollection = {
  __typename?: 'ManufacturerListRelationResponseCollection';
  data: Array<ManufacturerListEntity>;
};

export type ManufacturersAndPartner = {
  __typename?: 'ManufacturersAndPartner';
  content?: Maybe<Array<Maybe<ManufacturersAndPartnerContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ManufacturersAndPartnerRelationResponseCollection>;
  manufacturers?: Maybe<ComponentSharedManufacturers>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ManufacturersAndPartnerLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ManufacturersAndPartnerContentDynamicZone =
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ManufacturersAndPartnerEntity = {
  __typename?: 'ManufacturersAndPartnerEntity';
  attributes?: Maybe<ManufacturersAndPartner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ManufacturersAndPartnerEntityResponse = {
  __typename?: 'ManufacturersAndPartnerEntityResponse';
  data?: Maybe<ManufacturersAndPartnerEntity>;
};

export type ManufacturersAndPartnerInput = {
  content?: InputMaybe<
    Array<Scalars['ManufacturersAndPartnerContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  manufacturers?: InputMaybe<ComponentSharedManufacturersInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ManufacturersAndPartnerRelationResponseCollection = {
  __typename?: 'ManufacturersAndPartnerRelationResponseCollection';
  data: Array<ManufacturersAndPartnerEntity>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createCompanyLocalization?: Maybe<CompanyEntityResponse>;
  createCookiePolicyLocalization?: Maybe<CookiePolicyEntityResponse>;
  createCookieSettingLocalization?: Maybe<CookieSettingEntityResponse>;
  createCookiesBannerLocalization?: Maybe<CookiesBannerEntityResponse>;
  createFooterLocalization?: Maybe<FooterEntityResponse>;
  createGetInTouchLocalization?: Maybe<GetInTouchEntityResponse>;
  createHomeLocalization?: Maybe<HomeEntityResponse>;
  createManufacturerList?: Maybe<ManufacturerListEntityResponse>;
  createManufacturerListLocalization?: Maybe<ManufacturerListEntityResponse>;
  createManufacturersAndPartnerLocalization?: Maybe<ManufacturersAndPartnerEntityResponse>;
  createNavigationLocalization?: Maybe<NavigationEntityResponse>;
  createProduct?: Maybe<ProductEntityResponse>;
  createProductAndServiceLocalization?: Maybe<ProductAndServiceEntityResponse>;
  createProductLocalization?: Maybe<ProductEntityResponse>;
  createReferenceList?: Maybe<ReferenceListEntityResponse>;
  createReferenceListLocalization?: Maybe<ReferenceListEntityResponse>;
  createReferenceLocalization?: Maybe<ReferenceEntityResponse>;
  createServiceLocalization?: Maybe<ServiceEntityResponse>;
  createSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  createSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  createSocialResponsibilityLocalization?: Maybe<SocialResponsibilityEntityResponse>;
  createTableRow?: Maybe<TableRowEntityResponse>;
  createTableRowLocalization?: Maybe<TableRowEntityResponse>;
  createTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createWebexIntegrationLocalization?: Maybe<WebexIntegrationEntityResponse>;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  deleteCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  deleteCookieSetting?: Maybe<CookieSettingEntityResponse>;
  deleteCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  deleteFooter?: Maybe<FooterEntityResponse>;
  deleteGetInTouch?: Maybe<GetInTouchEntityResponse>;
  deleteHome?: Maybe<HomeEntityResponse>;
  deleteManufacturerList?: Maybe<ManufacturerListEntityResponse>;
  deleteManufacturersAndPartner?: Maybe<ManufacturersAndPartnerEntityResponse>;
  deleteNavigation?: Maybe<NavigationEntityResponse>;
  deleteProduct?: Maybe<ProductEntityResponse>;
  deleteProductAndService?: Maybe<ProductAndServiceEntityResponse>;
  deleteReference?: Maybe<ReferenceEntityResponse>;
  deleteReferenceList?: Maybe<ReferenceListEntityResponse>;
  deleteService?: Maybe<ServiceEntityResponse>;
  deleteSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  deleteSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  deleteSocialResponsibility?: Maybe<SocialResponsibilityEntityResponse>;
  deleteTableRow?: Maybe<TableRowEntityResponse>;
  deleteTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWebexIntegration?: Maybe<WebexIntegrationEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendContactForm?: Maybe<ContactFomResponse>;
  sendServiceForm?: Maybe<ServiceFomResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  updateCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  updateCookieSetting?: Maybe<CookieSettingEntityResponse>;
  updateCookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFooter?: Maybe<FooterEntityResponse>;
  updateGetInTouch?: Maybe<GetInTouchEntityResponse>;
  updateHome?: Maybe<HomeEntityResponse>;
  updateManufacturerList?: Maybe<ManufacturerListEntityResponse>;
  updateManufacturersAndPartner?: Maybe<ManufacturersAndPartnerEntityResponse>;
  updateNavigation?: Maybe<NavigationEntityResponse>;
  updateProduct?: Maybe<ProductEntityResponse>;
  updateProductAndService?: Maybe<ProductAndServiceEntityResponse>;
  updateReference?: Maybe<ReferenceEntityResponse>;
  updateReferenceList?: Maybe<ReferenceListEntityResponse>;
  updateService?: Maybe<ServiceEntityResponse>;
  updateSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  updateSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  updateSocialResponsibility?: Maybe<SocialResponsibilityEntityResponse>;
  updateTableRow?: Maybe<TableRowEntityResponse>;
  updateTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWebexIntegration?: Maybe<WebexIntegrationEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateCompanyLocalizationArgs = {
  data?: InputMaybe<CompanyInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookiePolicyLocalizationArgs = {
  data?: InputMaybe<CookiePolicyInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookieSettingLocalizationArgs = {
  data?: InputMaybe<CookieSettingInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookiesBannerLocalizationArgs = {
  data?: InputMaybe<CookiesBannerInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateFooterLocalizationArgs = {
  data?: InputMaybe<FooterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateGetInTouchLocalizationArgs = {
  data?: InputMaybe<GetInTouchInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateHomeLocalizationArgs = {
  data?: InputMaybe<HomeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateManufacturerListArgs = {
  data: ManufacturerListInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateManufacturerListLocalizationArgs = {
  data?: InputMaybe<ManufacturerListInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateManufacturersAndPartnerLocalizationArgs = {
  data?: InputMaybe<ManufacturersAndPartnerInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateNavigationLocalizationArgs = {
  data?: InputMaybe<NavigationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateProductArgs = {
  data: ProductInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateProductAndServiceLocalizationArgs = {
  data?: InputMaybe<ProductAndServiceInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateProductLocalizationArgs = {
  data?: InputMaybe<ProductInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateReferenceListArgs = {
  data: ReferenceListInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateReferenceListLocalizationArgs = {
  data?: InputMaybe<ReferenceListInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateReferenceLocalizationArgs = {
  data?: InputMaybe<ReferenceInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateServiceLocalizationArgs = {
  data?: InputMaybe<ServiceInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
};

export type MutationCreateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
};

export type MutationCreateSocialResponsibilityLocalizationArgs = {
  data?: InputMaybe<SocialResponsibilityInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTableRowArgs = {
  data: TableRowInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTableRowLocalizationArgs = {
  data?: InputMaybe<TableRowInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTranslateBatchTranslateJobArgs = {
  data: TranslateBatchTranslateJobInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationCreateWebexIntegrationLocalizationArgs = {
  data?: InputMaybe<WebexIntegrationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCompanyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookiePolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookieSettingArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookiesBannerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteFooterArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteGetInTouchArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteManufacturerListArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteManufacturersAndPartnerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteNavigationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteProductAndServiceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteReferenceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteReferenceListArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteServiceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteSitemapSitemapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapCacheArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSocialResponsibilityArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteTableRowArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteTranslateBatchTranslateJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteWebexIntegrationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSendContactFormArgs = {
  data: ContactFormDataInput;
};

export type MutationSendServiceFormArgs = {
  data: ServiceFormDataInput;
};

export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookiePolicyArgs = {
  data: CookiePolicyInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookieSettingArgs = {
  data: CookieSettingInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookiesBannerArgs = {
  data: CookiesBannerInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
  data: FooterInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateGetInTouchArgs = {
  data: GetInTouchInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateHomeArgs = {
  data: HomeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateManufacturerListArgs = {
  data: ManufacturerListInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateManufacturersAndPartnerArgs = {
  data: ManufacturersAndPartnerInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateNavigationArgs = {
  data: NavigationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateProductArgs = {
  data: ProductInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateProductAndServiceArgs = {
  data: ProductAndServiceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateReferenceArgs = {
  data: ReferenceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateReferenceListArgs = {
  data: ReferenceListInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateServiceArgs = {
  data: ServiceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSocialResponsibilityArgs = {
  data: SocialResponsibilityInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateTableRowArgs = {
  data: TableRowInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateTranslateBatchTranslateJobArgs = {
  data: TranslateBatchTranslateJobInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateWebexIntegrationArgs = {
  data: WebexIntegrationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<NavigationRelationResponseCollection>;
  menu?: Maybe<Array<Maybe<ComponentNavigationMenuItem>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NavigationLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type NavigationMenuArgs = {
  filters?: InputMaybe<ComponentNavigationMenuItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationEntity = {
  __typename?: 'NavigationEntity';
  attributes?: Maybe<Navigation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NavigationEntityResponse = {
  __typename?: 'NavigationEntityResponse';
  data?: Maybe<NavigationEntity>;
};

export type NavigationInput = {
  menu?: InputMaybe<Array<InputMaybe<ComponentNavigationMenuItemInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NavigationRelationResponseCollection = {
  __typename?: 'NavigationRelationResponseCollection';
  data: Array<NavigationEntity>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  __typename?: 'Product';
  content?: Maybe<Array<Maybe<ProductContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ProductRelationResponseCollection>;
  preview?: Maybe<ComponentSharedPreview>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductLocalizationsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductAndService = {
  __typename?: 'ProductAndService';
  content?: Maybe<Array<Maybe<ProductAndServiceContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ProductAndServiceRelationResponseCollection>;
  products?: Maybe<ComponentSharedProducts>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductAndServiceLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ProductAndServiceContentDynamicZone =
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ProductAndServiceEntity = {
  __typename?: 'ProductAndServiceEntity';
  attributes?: Maybe<ProductAndService>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProductAndServiceEntityResponse = {
  __typename?: 'ProductAndServiceEntityResponse';
  data?: Maybe<ProductAndServiceEntity>;
};

export type ProductAndServiceInput = {
  content?: InputMaybe<
    Array<Scalars['ProductAndServiceContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  products?: InputMaybe<ComponentSharedProductsInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ProductAndServiceRelationResponseCollection = {
  __typename?: 'ProductAndServiceRelationResponseCollection';
  data: Array<ProductAndServiceEntity>;
};

export type ProductContentDynamicZone =
  | ComponentSharedLargeImageSection
  | ComponentSharedTextImageSection
  | ComponentSharedTwoImagesGrid
  | ComponentSharedTwoVideosGrid
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ProductEntity = {
  __typename?: 'ProductEntity';
  attributes?: Maybe<Product>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProductEntityResponse = {
  __typename?: 'ProductEntityResponse';
  data?: Maybe<ProductEntity>;
};

export type ProductEntityResponseCollection = {
  __typename?: 'ProductEntityResponseCollection';
  data: Array<ProductEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionFiltersInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ProductFiltersInput>;
  not?: InputMaybe<ProductFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  preview?: InputMaybe<ComponentSharedPreviewFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug_cs?: InputMaybe<StringFilterInput>;
  slug_de?: InputMaybe<StringFilterInput>;
  slug_en?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductInput = {
  content?: InputMaybe<
    Array<Scalars['ProductContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  preview?: InputMaybe<ComponentSharedPreviewInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ProductRelationResponseCollection = {
  __typename?: 'ProductRelationResponseCollection';
  data: Array<ProductEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  company?: Maybe<CompanyEntityResponse>;
  cookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  cookieSetting?: Maybe<CookieSettingEntityResponse>;
  cookiesBanner?: Maybe<CookiesBannerEntityResponse>;
  footer?: Maybe<FooterEntityResponse>;
  getInTouch?: Maybe<GetInTouchEntityResponse>;
  home?: Maybe<HomeEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  manufacturerList?: Maybe<ManufacturerListEntityResponse>;
  manufacturerLists?: Maybe<ManufacturerListEntityResponseCollection>;
  manufacturersAndPartner?: Maybe<ManufacturersAndPartnerEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  navigation?: Maybe<NavigationEntityResponse>;
  product?: Maybe<ProductEntityResponse>;
  productAndService?: Maybe<ProductAndServiceEntityResponse>;
  products?: Maybe<ProductEntityResponseCollection>;
  reference?: Maybe<ReferenceEntityResponse>;
  referenceList?: Maybe<ReferenceListEntityResponse>;
  referenceLists?: Maybe<ReferenceListEntityResponseCollection>;
  service?: Maybe<ServiceEntityResponse>;
  sitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  sitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  sitemapSitemapCaches?: Maybe<SitemapSitemapCacheEntityResponseCollection>;
  sitemapSitemaps?: Maybe<SitemapSitemapEntityResponseCollection>;
  socialResponsibility?: Maybe<SocialResponsibilityEntityResponse>;
  tableRow?: Maybe<TableRowEntityResponse>;
  tableRows?: Maybe<TableRowEntityResponseCollection>;
  translateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  translateBatchTranslateJobs?: Maybe<TranslateBatchTranslateJobEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  webexIntegration?: Maybe<WebexIntegrationEntityResponse>;
};

export type QueryCompanyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiePolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookieSettingArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiesBannerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryFooterArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGetInTouchArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryManufacturerListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryManufacturerListsArgs = {
  filters?: InputMaybe<ManufacturerListFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryManufacturersAndPartnerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryNavigationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryProductAndServiceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryReferenceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryReferenceListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryReferenceListsArgs = {
  filters?: InputMaybe<ReferenceListFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryServiceArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySitemapSitemapArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCacheArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCachesArgs = {
  filters?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySitemapSitemapsArgs = {
  filters?: InputMaybe<SitemapSitemapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySocialResponsibilityArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryTableRowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryTableRowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTranslateBatchTranslateJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTranslateBatchTranslateJobsArgs = {
  filters?: InputMaybe<TranslateBatchTranslateJobFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryWebexIntegrationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type Reference = {
  __typename?: 'Reference';
  content?: Maybe<Array<Maybe<ReferenceContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ReferenceRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  references?: Maybe<ComponentSharedReferences>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferenceLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ReferenceContentDynamicZone =
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ReferenceEntity = {
  __typename?: 'ReferenceEntity';
  attributes?: Maybe<Reference>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ReferenceEntityResponse = {
  __typename?: 'ReferenceEntityResponse';
  data?: Maybe<ReferenceEntity>;
};

export type ReferenceInput = {
  content?: InputMaybe<
    Array<Scalars['ReferenceContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  references?: InputMaybe<ComponentSharedReferencesInput>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ReferenceList = {
  __typename?: 'ReferenceList';
  content?: Maybe<Array<Maybe<ReferenceListContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ReferenceListRelationResponseCollection>;
  preview?: Maybe<ComponentSharedPreview>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferenceListLocalizationsArgs = {
  filters?: InputMaybe<ReferenceListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ReferenceListContentDynamicZone =
  | ComponentSharedLargeImageSection
  | ComponentSharedTextImageSection
  | ComponentSharedTwoImagesGrid
  | ComponentSharedTwoVideosGrid
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ReferenceListEntity = {
  __typename?: 'ReferenceListEntity';
  attributes?: Maybe<ReferenceList>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ReferenceListEntityResponse = {
  __typename?: 'ReferenceListEntityResponse';
  data?: Maybe<ReferenceListEntity>;
};

export type ReferenceListEntityResponseCollection = {
  __typename?: 'ReferenceListEntityResponseCollection';
  data: Array<ReferenceListEntity>;
  meta: ResponseCollectionMeta;
};

export type ReferenceListFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReferenceListFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionFiltersInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ReferenceListFiltersInput>;
  not?: InputMaybe<ReferenceListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReferenceListFiltersInput>>>;
  preview?: InputMaybe<ComponentSharedPreviewFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug_cs?: InputMaybe<StringFilterInput>;
  slug_de?: InputMaybe<StringFilterInput>;
  slug_en?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReferenceListInput = {
  content?: InputMaybe<
    Array<Scalars['ReferenceListContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  preview?: InputMaybe<ComponentSharedPreviewInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ReferenceListRelationResponseCollection = {
  __typename?: 'ReferenceListRelationResponseCollection';
  data: Array<ReferenceListEntity>;
};

export type ReferenceRelationResponseCollection = {
  __typename?: 'ReferenceRelationResponseCollection';
  data: Array<ReferenceEntity>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type Service = {
  __typename?: 'Service';
  content?: Maybe<Array<Maybe<ServiceContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ServiceRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ServiceLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ServiceContentDynamicZone =
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type ServiceEntity = {
  __typename?: 'ServiceEntity';
  attributes?: Maybe<Service>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ServiceEntityResponse = {
  __typename?: 'ServiceEntityResponse';
  data?: Maybe<ServiceEntity>;
};

export type ServiceFomResponse = {
  __typename?: 'ServiceFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ServiceFormDataInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  issueDescription: Scalars['String']['input'];
  issueSubject: Scalars['String']['input'];
  terms: Scalars['Boolean']['input'];
};

export type ServiceInput = {
  content?: InputMaybe<
    Array<Scalars['ServiceContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceRelationResponseCollection = {
  __typename?: 'ServiceRelationResponseCollection';
  data: Array<ServiceEntity>;
};

export type SitemapSitemap = {
  __typename?: 'SitemapSitemap';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  link_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  sitemap_string: Scalars['String']['output'];
  type?: Maybe<Enum_Sitemapsitemap_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCache = {
  __typename?: 'SitemapSitemapCache';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sitemap_id: Scalars['Int']['output'];
  sitemap_json: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCacheEntity = {
  __typename?: 'SitemapSitemapCacheEntity';
  attributes?: Maybe<SitemapSitemapCache>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapCacheEntityResponse = {
  __typename?: 'SitemapSitemapCacheEntityResponse';
  data?: Maybe<SitemapSitemapCacheEntity>;
};

export type SitemapSitemapCacheEntityResponseCollection = {
  __typename?: 'SitemapSitemapCacheEntityResponseCollection';
  data: Array<SitemapSitemapCacheEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapCacheFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  sitemap_id?: InputMaybe<IntFilterInput>;
  sitemap_json?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapCacheInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_id?: InputMaybe<Scalars['Int']['input']>;
  sitemap_json?: InputMaybe<Scalars['JSON']['input']>;
};

export type SitemapSitemapEntity = {
  __typename?: 'SitemapSitemapEntity';
  attributes?: Maybe<SitemapSitemap>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapEntityResponse = {
  __typename?: 'SitemapSitemapEntityResponse';
  data?: Maybe<SitemapSitemapEntity>;
};

export type SitemapSitemapEntityResponseCollection = {
  __typename?: 'SitemapSitemapEntityResponseCollection';
  data: Array<SitemapSitemapEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  delta?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link_count?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  sitemap_string?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapInput = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  link_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_string?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Sitemapsitemap_Type>;
};

export type SocialResponsibility = {
  __typename?: 'SocialResponsibility';
  content?: Maybe<Array<Maybe<SocialResponsibilityContentDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentSharedHeroImageSection>;
  heroVideoSection?: Maybe<ComponentSharedHeroVideoSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<SocialResponsibilityRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug_cs?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SocialResponsibilityLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type SocialResponsibilityContentDynamicZone =
  | ComponentUiImage
  | ComponentUiTextContent
  | Error;

export type SocialResponsibilityEntity = {
  __typename?: 'SocialResponsibilityEntity';
  attributes?: Maybe<SocialResponsibility>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SocialResponsibilityEntityResponse = {
  __typename?: 'SocialResponsibilityEntityResponse';
  data?: Maybe<SocialResponsibilityEntity>;
};

export type SocialResponsibilityInput = {
  content?: InputMaybe<
    Array<Scalars['SocialResponsibilityContentDynamicZoneInput']['input']>
  >;
  heroImageSection?: InputMaybe<ComponentSharedHeroImageSectionInput>;
  heroVideoSection?: InputMaybe<ComponentSharedHeroVideoSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug_cs?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
};

export type SocialResponsibilityRelationResponseCollection = {
  __typename?: 'SocialResponsibilityRelationResponseCollection';
  data: Array<SocialResponsibilityEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TableRow = {
  __typename?: 'TableRow';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<TableRowRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rows?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TableRowLocalizationsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowRowsArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowEntity = {
  __typename?: 'TableRowEntity';
  attributes?: Maybe<TableRow>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TableRowEntityResponse = {
  __typename?: 'TableRowEntityResponse';
  data?: Maybe<TableRowEntity>;
};

export type TableRowEntityResponseCollection = {
  __typename?: 'TableRowEntityResponseCollection';
  data: Array<TableRowEntity>;
  meta: ResponseCollectionMeta;
};

export type TableRowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<TableRowFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TableRowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rows?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TableRowInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemInput>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRowRelationResponseCollection = {
  __typename?: 'TableRowRelationResponseCollection';
  data: Array<TableRowEntity>;
};

export type TranslateBatchTranslateJob = {
  __typename?: 'TranslateBatchTranslateJob';
  autoPublish?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityIds?: Maybe<Scalars['JSON']['output']>;
  failureReason?: Maybe<Scalars['JSON']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  sourceLocale?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Enum_Translatebatchtranslatejob_Status>;
  targetLocale?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TranslateBatchTranslateJobEntity = {
  __typename?: 'TranslateBatchTranslateJobEntity';
  attributes?: Maybe<TranslateBatchTranslateJob>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TranslateBatchTranslateJobEntityResponse = {
  __typename?: 'TranslateBatchTranslateJobEntityResponse';
  data?: Maybe<TranslateBatchTranslateJobEntity>;
};

export type TranslateBatchTranslateJobEntityResponseCollection = {
  __typename?: 'TranslateBatchTranslateJobEntityResponseCollection';
  data: Array<TranslateBatchTranslateJobEntity>;
  meta: ResponseCollectionMeta;
};

export type TranslateBatchTranslateJobFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TranslateBatchTranslateJobFiltersInput>>>;
  autoPublish?: InputMaybe<BooleanFilterInput>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  entityIds?: InputMaybe<JsonFilterInput>;
  failureReason?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<TranslateBatchTranslateJobFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TranslateBatchTranslateJobFiltersInput>>>;
  progress?: InputMaybe<FloatFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  sourceLocale?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  targetLocale?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TranslateBatchTranslateJobInput = {
  autoPublish?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Scalars['JSON']['input']>;
  failureReason?: InputMaybe<Scalars['JSON']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLocale?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Translatebatchtranslatejob_Status>;
  targetLocale?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  blurhash?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  blurhash?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WebexIntegration = {
  __typename?: 'WebexIntegration';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<WebexIntegrationRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WebexIntegrationLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type WebexIntegrationEntity = {
  __typename?: 'WebexIntegrationEntity';
  attributes?: Maybe<WebexIntegration>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type WebexIntegrationEntityResponse = {
  __typename?: 'WebexIntegrationEntityResponse';
  data?: Maybe<WebexIntegrationEntity>;
};

export type WebexIntegrationInput = {
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type WebexIntegrationRelationResponseCollection = {
  __typename?: 'WebexIntegrationRelationResponseCollection';
  data: Array<WebexIntegrationEntity>;
};

export type AboutUsSeoQueryVariables = Exact<{ [key: string]: never }>;

export type AboutUsSeoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutUsHeroVideoQueryVariables = Exact<{ [key: string]: never }>;

export type AboutUsHeroVideoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutUsHeroImageQueryVariables = Exact<{ [key: string]: never }>;

export type AboutUsHeroImageQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutUsContentFragment = {
  __typename?: 'Company';
  content?: Array<
    | {
        __typename?: 'ComponentSharedOurTeam';
        anchor?: string | null;
        sectionContent?: {
          __typename?: 'ComponentSharedSectionContent';
          title?: string | null;
          subtitle?: string | null;
          content?: {
            __typename?: 'ComponentUiTextContent';
            text?: any | null;
          } | null;
        } | null;
        item?: Array<{
          __typename?: 'ComponentSharedOurTeamItem';
          name?: string | null;
          position?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type AboutUsContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutUsContentMainQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyEntityResponse';
    data?: {
      __typename?: 'CompanyEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Company';
        content?: Array<
          | {
              __typename?: 'ComponentSharedOurTeam';
              anchor?: string | null;
              sectionContent?: {
                __typename?: 'ComponentSharedSectionContent';
                title?: string | null;
                subtitle?: string | null;
                content?: {
                  __typename?: 'ComponentUiTextContent';
                  text?: any | null;
                } | null;
              } | null;
              item?: Array<{
                __typename?: 'ComponentSharedOurTeamItem';
                name?: string | null;
                position?: string | null;
                image?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type HeroVideoSectionFragment = {
  __typename?: 'ComponentSharedHeroVideoSection';
  id: string;
  title?: string | null;
  subtitle?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
  heroVideo?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HeroImageSectionFragment = {
  __typename?: 'ComponentSharedHeroImageSection';
  id: string;
  title?: string | null;
  subtitle?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
  heroImage?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UploadFileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      alternativeText?: string | null;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type UploadDocumentFragment = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<{
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      size: number;
      blurhash?: string | null;
    } | null;
  }>;
};

export type ButtonFragment = {
  __typename?: 'ComponentUiButton';
  id: string;
  label: string;
  path?: string | null;
  openInNewTab?: boolean | null;
};

export type ImageFragment = {
  __typename?: 'ComponentUiImage';
  id: string;
  alt: string;
  img_desktop: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type VideoFragment = {
  __typename?: 'ComponentUiVideo';
  poster_alt?: string | null;
  poster: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  };
  mobilePoster?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  video?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SendContactFormMutationVariables = Exact<{
  data: ContactFormDataInput;
}>;

export type SendContactFormMutation = {
  __typename?: 'Mutation';
  sendContactForm?: {
    __typename?: 'ContactFomResponse';
    success?: boolean | null;
  } | null;
};

export type SendServiceFormMutationVariables = Exact<{
  data: ServiceFormDataInput;
}>;

export type SendServiceFormMutation = {
  __typename?: 'Mutation';
  sendServiceForm?: {
    __typename?: 'ServiceFomResponse';
    success?: boolean | null;
  } | null;
};

export type HomeSeoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type HomeSeoQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeHeroVideoQueryVariables = Exact<{ [key: string]: never }>;

export type HomeHeroVideoQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeHeroImageQueryVariables = Exact<{ [key: string]: never }>;

export type HomeHeroImageQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HomeContentFragment = {
  __typename?: 'Home';
  content?: Array<
    | {
        __typename?: 'ComponentSharedLogoCarousel';
        title?: string | null;
        anchor?: string | null;
        button?: {
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null;
        item?: Array<{
          __typename?: 'ComponentSharedLogoCarouselItem';
          link?: {
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedWorkflow';
        anchor?: string | null;
        sectionContent?: {
          __typename?: 'ComponentSharedSectionContent';
          title?: string | null;
          subtitle?: string | null;
          content?: {
            __typename?: 'ComponentUiTextContent';
            text?: any | null;
          } | null;
        } | null;
        item?: Array<{
          __typename?: 'ComponentSharedTextItem';
          title?: string | null;
          description?: string | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type HomeContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type HomeContentMainQuery = {
  __typename?: 'Query';
  home?: {
    __typename?: 'HomeEntityResponse';
    data?: {
      __typename?: 'HomeEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Home';
        content?: Array<
          | {
              __typename?: 'ComponentSharedLogoCarousel';
              title?: string | null;
              anchor?: string | null;
              button?: {
                __typename?: 'ComponentUiButton';
                id: string;
                label: string;
                path?: string | null;
                openInNewTab?: boolean | null;
              } | null;
              item?: Array<{
                __typename?: 'ComponentSharedLogoCarouselItem';
                link?: {
                  __typename?: 'ComponentUiLink';
                  label: string;
                  path: string;
                  openInNewTab?: boolean | null;
                } | null;
                image?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedWorkflow';
              anchor?: string | null;
              sectionContent?: {
                __typename?: 'ComponentSharedSectionContent';
                title?: string | null;
                subtitle?: string | null;
                content?: {
                  __typename?: 'ComponentUiTextContent';
                  text?: any | null;
                } | null;
              } | null;
              item?: Array<{
                __typename?: 'ComponentSharedTextItem';
                title?: string | null;
                description?: string | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type FormContactWebexQueryVariables = Exact<{ [key: string]: never }>;

export type FormContactWebexQuery = {
  __typename?: 'Query';
  webexIntegration?: {
    __typename?: 'WebexIntegrationEntityResponse';
    data?: {
      __typename?: 'WebexIntegrationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'WebexIntegration';
        slug?: string | null;
      } | null;
    } | null;
  } | null;
};

export type LinkFragment = {
  __typename?: 'ComponentUiLink';
  label: string;
  path: string;
  openInNewTab?: boolean | null;
};

export type NavigationMenuItemFragment = {
  __typename?: 'ComponentNavigationMenuItem';
  label?: string | null;
  isButton?: boolean | null;
  link?: {
    __typename?: 'ComponentUiLink';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
  } | null;
  subLink?: Array<{
    __typename?: 'ComponentUiLink';
    label: string;
    path: string;
    openInNewTab?: boolean | null;
  } | null> | null;
};

export type FooterQueryVariables = Exact<{ [key: string]: never }>;

export type FooterQuery = {
  __typename?: 'Query';
  footer?: {
    __typename?: 'FooterEntityResponse';
    data?: {
      __typename?: 'FooterEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Footer';
        column?: Array<{
          __typename?: 'ComponentFooterColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null> | null;
        } | null> | null;
        policies?: {
          __typename?: 'ComponentFooterPolicies';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null> | null;
        } | null;
        contact?: {
          __typename?: 'ComponentFooterContact';
          label?: string | null;
          phone?: {
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null;
          email?: {
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'NavigationEntityResponse';
    data?: {
      __typename?: 'NavigationEntity';
      attributes?: {
        __typename?: 'Navigation';
        menu?: Array<{
          __typename?: 'ComponentNavigationMenuItem';
          label?: string | null;
          isButton?: boolean | null;
          link?: {
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null;
          subLink?: Array<{
            __typename?: 'ComponentUiLink';
            label: string;
            path: string;
            openInNewTab?: boolean | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturerListPreviewFragment = {
  __typename?: 'ComponentSharedPreview';
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
};

export type ManufacturerListFragment = {
  __typename?: 'ManufacturerList';
  slug_cs?: string | null;
  slug_en?: string | null;
  slug_de?: string | null;
  heroImageSection?: {
    __typename?: 'ComponentSharedHeroImageSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroImage?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideoSection?: {
    __typename?: 'ComponentSharedHeroVideoSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroVideo?: {
      __typename?: 'ComponentUiVideo';
      poster_alt?: string | null;
      poster: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      mobilePoster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  content?: Array<
    | {
        __typename?: 'ComponentSharedLargeImageSection';
        title?: string | null;
        anchor?: string | null;
        description?: string | null;
        button?: {
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'ComponentSharedTextImageSection';
        anchor?: string | null;
        title?: string | null;
        text?: any | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        IconSpec?: Array<{
          __typename?: 'ComponentSharedIconSpec';
          text?: string | null;
          icon?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoImagesGrid';
        anchor?: string | null;
        title?: string | null;
        imagesGridItems?: Array<{
          __typename?: 'ComponentSharedImagesGridItem';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoVideosGrid';
        anchor?: string | null;
        title?: string | null;
        videosGridItems?: Array<{
          __typename?: 'ComponentSharedVideosGridItem';
          title?: string | null;
          description?: string | null;
          video?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
  seo?: {
    __typename?: 'ComponentSharedSeo';
    id: string;
    canonicalURL?: string | null;
    keywords?: string | null;
    metaDescription: string;
    metaRobots?: string | null;
    metaTitle: string;
    metaViewport?: string | null;
    structuredData?: any | null;
    metaImage?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    metaSocial?: Array<{
      __typename?: 'ComponentSharedMetaSocial';
      id: string;
      title: string;
      description: string;
      socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ManufacturerListSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ManufacturerListSectionQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        manufacturers?: {
          __typename?: 'ComponentSharedManufacturers';
          title?: string | null;
          anchor?: string | null;
          manufacturer_lists?: {
            __typename?: 'ManufacturerListRelationResponseCollection';
            data: Array<{
              __typename?: 'ManufacturerListEntity';
              attributes?: {
                __typename?: 'ManufacturerList';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturersQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManufacturersQuery = {
  __typename?: 'Query';
  manufacturerLists?: {
    __typename?: 'ManufacturerListEntityResponseCollection';
    data: Array<{
      __typename?: 'ManufacturerListEntity';
      attributes?: {
        __typename?: 'ManufacturerList';
        slug_cs?: string | null;
        slug_en?: string | null;
        slug_de?: string | null;
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        content?: Array<
          | {
              __typename?: 'ComponentSharedLargeImageSection';
              title?: string | null;
              anchor?: string | null;
              description?: string | null;
              button?: {
                __typename?: 'ComponentUiButton';
                id: string;
                label: string;
                path?: string | null;
                openInNewTab?: boolean | null;
              } | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentSharedTextImageSection';
              anchor?: string | null;
              title?: string | null;
              text?: any | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              IconSpec?: Array<{
                __typename?: 'ComponentSharedIconSpec';
                text?: string | null;
                icon?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoImagesGrid';
              anchor?: string | null;
              title?: string | null;
              imagesGridItems?: Array<{
                __typename?: 'ComponentSharedImagesGridItem';
                title?: string | null;
                description?: string | null;
                image?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoVideosGrid';
              anchor?: string | null;
              title?: string | null;
              videosGridItems?: Array<{
                __typename?: 'ComponentSharedVideosGridItem';
                title?: string | null;
                description?: string | null;
                video?: {
                  __typename?: 'ComponentUiVideo';
                  poster_alt?: string | null;
                  poster: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  mobilePoster?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  video?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ManufacturerPartnerSeoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ManufacturerPartnerSeoQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturerPartnerHeroVideoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ManufacturerPartnerHeroVideoQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturerPartnerHeroImageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ManufacturerPartnerHeroImageQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturerPartnerContentFragment = {
  __typename?: 'ManufacturersAndPartner';
  content?: Array<
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type ManufacturerPartnerContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type ManufacturerPartnerContentMainQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        content?: Array<
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type ManufacturerPartnerSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ManufacturerPartnerSectionQuery = {
  __typename?: 'Query';
  manufacturersAndPartner?: {
    __typename?: 'ManufacturersAndPartnerEntityResponse';
    data?: {
      __typename?: 'ManufacturersAndPartnerEntity';
      attributes?: {
        __typename?: 'ManufacturersAndPartner';
        manufacturers?: {
          __typename?: 'ComponentSharedManufacturers';
          title?: string | null;
          anchor?: string | null;
          manufacturer_lists?: {
            __typename?: 'ManufacturerListRelationResponseCollection';
            data: Array<{
              __typename?: 'ManufacturerListEntity';
              attributes?: {
                __typename?: 'ManufacturerList';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesMainHeading_Fragment = {
  __typename?: 'ComponentPoliciesMainHeading';
  text?: any | null;
};

type PolicyFields_ComponentPoliciesTableTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTableTextContent';
  richText?: any | null;
  tableHead?: Array<{
    __typename?: 'ComponentPoliciesTableHeading';
    headingItem?: string | null;
  } | null> | null;
  tableRow?: {
    __typename?: 'ComponentPoliciesTableRow';
    table_rows?: {
      __typename?: 'TableRowRelationResponseCollection';
      data: Array<{
        __typename?: 'TableRowEntity';
        attributes?: {
          __typename?: 'TableRow';
          rows?: Array<{
            __typename?: 'ComponentPoliciesRowItem';
            item?: string | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesTitleTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  text?: any | null;
};

type PolicyFields_Error_Fragment = { __typename?: 'Error' };

export type PolicyFieldsFragment =
  | PolicyFields_ComponentPoliciesMainHeading_Fragment
  | PolicyFields_ComponentPoliciesTableTextContent_Fragment
  | PolicyFields_ComponentPoliciesTitleTextContent_Fragment
  | PolicyFields_Error_Fragment;

export type CookiePolicyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiePolicyQuery = {
  __typename?: 'Query';
  cookiePolicy?: {
    __typename?: 'CookiePolicyEntityResponse';
    data?: {
      __typename?: 'CookiePolicyEntity';
      attributes?: {
        __typename?: 'CookiePolicy';
        cookiePolicy?: Array<
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHeading';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesSettingsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiesSettingsQuery = {
  __typename?: 'Query';
  cookieSetting?: {
    __typename?: 'CookieSettingEntityResponse';
    data?: {
      __typename?: 'CookieSettingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookieSetting';
        actionButtonLabel?: string | null;
        necessaryCookiesHeading?: string | null;
        optionalCookiesHeading?: string | null;
        title?: string | null;
        necessaryCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        optionalCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHeading';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        redirectButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesBarQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiesBarQuery = {
  __typename?: 'Query';
  cookiesBanner?: {
    __typename?: 'CookiesBannerEntityResponse';
    data?: {
      __typename?: 'CookiesBannerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookiesBanner';
        heading?: string | null;
        editButtonLabel?: string | null;
        declineButtonLabel?: string | null;
        acceptButtonLabel?: string | null;
        text?: any | null;
        extraLinks?: Array<{
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null> | null;
        toggleText?: {
          __typename?: 'ComponentSharedToggleText';
          firstText?: string | null;
          secondText?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductPreviewFragment = {
  __typename?: 'ComponentSharedPreview';
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
};

export type ProductFragment = {
  __typename?: 'Product';
  slug_cs?: string | null;
  slug_en?: string | null;
  slug_de?: string | null;
  heroImageSection?: {
    __typename?: 'ComponentSharedHeroImageSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroImage?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideoSection?: {
    __typename?: 'ComponentSharedHeroVideoSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroVideo?: {
      __typename?: 'ComponentUiVideo';
      poster_alt?: string | null;
      poster: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      mobilePoster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  content?: Array<
    | {
        __typename?: 'ComponentSharedLargeImageSection';
        title?: string | null;
        anchor?: string | null;
        description?: string | null;
        button?: {
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'ComponentSharedTextImageSection';
        anchor?: string | null;
        title?: string | null;
        text?: any | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        IconSpec?: Array<{
          __typename?: 'ComponentSharedIconSpec';
          text?: string | null;
          icon?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoImagesGrid';
        anchor?: string | null;
        title?: string | null;
        imagesGridItems?: Array<{
          __typename?: 'ComponentSharedImagesGridItem';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoVideosGrid';
        anchor?: string | null;
        title?: string | null;
        videosGridItems?: Array<{
          __typename?: 'ComponentSharedVideosGridItem';
          title?: string | null;
          description?: string | null;
          video?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
  seo?: {
    __typename?: 'ComponentSharedSeo';
    id: string;
    canonicalURL?: string | null;
    keywords?: string | null;
    metaDescription: string;
    metaRobots?: string | null;
    metaTitle: string;
    metaViewport?: string | null;
    structuredData?: any | null;
    metaImage?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    metaSocial?: Array<{
      __typename?: 'ComponentSharedMetaSocial';
      id: string;
      title: string;
      description: string;
      socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProductsSectionQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsSectionQuery = {
  __typename?: 'Query';
  productAndService?: {
    __typename?: 'ProductAndServiceEntityResponse';
    data?: {
      __typename?: 'ProductAndServiceEntity';
      attributes?: {
        __typename?: 'ProductAndService';
        products?: {
          __typename?: 'ComponentSharedProducts';
          title?: string | null;
          anchor?: string | null;
          products?: {
            __typename?: 'ProductRelationResponseCollection';
            data: Array<{
              __typename?: 'ProductEntity';
              attributes?: {
                __typename?: 'Product';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  products?: {
    __typename?: 'ProductEntityResponseCollection';
    data: Array<{
      __typename?: 'ProductEntity';
      attributes?: {
        __typename?: 'Product';
        slug_cs?: string | null;
        slug_en?: string | null;
        slug_de?: string | null;
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        content?: Array<
          | {
              __typename?: 'ComponentSharedLargeImageSection';
              title?: string | null;
              anchor?: string | null;
              description?: string | null;
              button?: {
                __typename?: 'ComponentUiButton';
                id: string;
                label: string;
                path?: string | null;
                openInNewTab?: boolean | null;
              } | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentSharedTextImageSection';
              anchor?: string | null;
              title?: string | null;
              text?: any | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              IconSpec?: Array<{
                __typename?: 'ComponentSharedIconSpec';
                text?: string | null;
                icon?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoImagesGrid';
              anchor?: string | null;
              title?: string | null;
              imagesGridItems?: Array<{
                __typename?: 'ComponentSharedImagesGridItem';
                title?: string | null;
                description?: string | null;
                image?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoVideosGrid';
              anchor?: string | null;
              title?: string | null;
              videosGridItems?: Array<{
                __typename?: 'ComponentSharedVideosGridItem';
                title?: string | null;
                description?: string | null;
                video?: {
                  __typename?: 'ComponentUiVideo';
                  poster_alt?: string | null;
                  poster: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  mobilePoster?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  video?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ProductServiceSeoQueryVariables = Exact<{ [key: string]: never }>;

export type ProductServiceSeoQuery = {
  __typename?: 'Query';
  productAndService?: {
    __typename?: 'ProductAndServiceEntityResponse';
    data?: {
      __typename?: 'ProductAndServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ProductAndService';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductAndServiceHeroVideoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductAndServiceHeroVideoQuery = {
  __typename?: 'Query';
  productAndService?: {
    __typename?: 'ProductAndServiceEntityResponse';
    data?: {
      __typename?: 'ProductAndServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ProductAndService';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductAndServiceHeroImageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductAndServiceHeroImageQuery = {
  __typename?: 'Query';
  productAndService?: {
    __typename?: 'ProductAndServiceEntityResponse';
    data?: {
      __typename?: 'ProductAndServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'ProductAndService';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ProductServiceFragment = {
  __typename?: 'Product';
  slug_cs?: string | null;
  slug_en?: string | null;
  slug_de?: string | null;
  content?: Array<
    | { __typename?: 'ComponentSharedLargeImageSection' }
    | { __typename?: 'ComponentSharedTextImageSection' }
    | { __typename?: 'ComponentSharedTwoImagesGrid' }
    | { __typename?: 'ComponentSharedTwoVideosGrid' }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type ProductsServicesProductsSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductsServicesProductsSectionQuery = {
  __typename?: 'Query';
  productAndService?: {
    __typename?: 'ProductAndServiceEntityResponse';
    data?: {
      __typename?: 'ProductAndServiceEntity';
      attributes?: {
        __typename?: 'ProductAndService';
        products?: {
          __typename?: 'ComponentSharedProducts';
          title?: string | null;
          anchor?: string | null;
          products?: {
            __typename?: 'ProductRelationResponseCollection';
            data: Array<{
              __typename?: 'ProductEntity';
              attributes?: {
                __typename?: 'Product';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceSeoQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceSeoQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Reference';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceHeroVideoQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceHeroVideoQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Reference';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceHeroImageQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceHeroImageQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Reference';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceContentFragment = {
  __typename?: 'Reference';
  content?: Array<
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type ReferenceContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type ReferenceContentMainQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Reference';
        content?: Array<
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceSectionQueryVariables = Exact<{ [key: string]: never }>;

export type ReferenceSectionQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      attributes?: {
        __typename?: 'Reference';
        references?: {
          __typename?: 'ComponentSharedReferences';
          title?: string | null;
          anchor?: string | null;
          reference_lists?: {
            __typename?: 'ReferenceListRelationResponseCollection';
            data: Array<{
              __typename?: 'ReferenceListEntity';
              attributes?: {
                __typename?: 'ReferenceList';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferenceListPreviewFragment = {
  __typename?: 'ComponentSharedPreview';
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
};

export type ReferenceListFragment = {
  __typename?: 'ReferenceList';
  slug_cs?: string | null;
  slug_en?: string | null;
  slug_de?: string | null;
  heroImageSection?: {
    __typename?: 'ComponentSharedHeroImageSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroImage?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideoSection?: {
    __typename?: 'ComponentSharedHeroVideoSection';
    id: string;
    title?: string | null;
    subtitle?: string | null;
    button?: {
      __typename?: 'ComponentUiButton';
      id: string;
      label: string;
      path?: string | null;
      openInNewTab?: boolean | null;
    } | null;
    heroVideo?: {
      __typename?: 'ComponentUiVideo';
      poster_alt?: string | null;
      poster: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      mobilePoster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  content?: Array<
    | {
        __typename?: 'ComponentSharedLargeImageSection';
        title?: string | null;
        anchor?: string | null;
        description?: string | null;
        button?: {
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'ComponentSharedTextImageSection';
        anchor?: string | null;
        title?: string | null;
        text?: any | null;
        image?: {
          __typename?: 'ComponentUiImage';
          id: string;
          alt: string;
          img_desktop: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          };
          img_mobile?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        IconSpec?: Array<{
          __typename?: 'ComponentSharedIconSpec';
          text?: string | null;
          icon?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoImagesGrid';
        anchor?: string | null;
        title?: string | null;
        imagesGridItems?: Array<{
          __typename?: 'ComponentSharedImagesGridItem';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentSharedTwoVideosGrid';
        anchor?: string | null;
        title?: string | null;
        videosGridItems?: Array<{
          __typename?: 'ComponentSharedVideosGridItem';
          title?: string | null;
          description?: string | null;
          video?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
  seo?: {
    __typename?: 'ComponentSharedSeo';
    id: string;
    canonicalURL?: string | null;
    keywords?: string | null;
    metaDescription: string;
    metaRobots?: string | null;
    metaTitle: string;
    metaViewport?: string | null;
    structuredData?: any | null;
    metaImage?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    metaSocial?: Array<{
      __typename?: 'ComponentSharedMetaSocial';
      id: string;
      title: string;
      description: string;
      socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
      image?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ReferenceListSectionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReferenceListSectionQuery = {
  __typename?: 'Query';
  reference?: {
    __typename?: 'ReferenceEntityResponse';
    data?: {
      __typename?: 'ReferenceEntity';
      attributes?: {
        __typename?: 'Reference';
        references?: {
          __typename?: 'ComponentSharedReferences';
          title?: string | null;
          anchor?: string | null;
          reference_lists?: {
            __typename?: 'ReferenceListRelationResponseCollection';
            data: Array<{
              __typename?: 'ReferenceListEntity';
              attributes?: {
                __typename?: 'ReferenceList';
                slug_cs?: string | null;
                slug_de?: string | null;
                slug_en?: string | null;
                preview?: {
                  __typename?: 'ComponentSharedPreview';
                  title?: string | null;
                  description?: string | null;
                  image?: {
                    __typename?: 'ComponentUiImage';
                    id: string;
                    alt: string;
                    img_desktop: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    };
                    img_mobile?: {
                      __typename?: 'UploadFileEntityResponse';
                      data?: {
                        __typename?: 'UploadFileEntity';
                        id?: string | null;
                        attributes?: {
                          __typename?: 'UploadFile';
                          url: string;
                          alternativeText?: string | null;
                          blurhash?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'ComponentUiButton';
                    id: string;
                    label: string;
                    path?: string | null;
                    openInNewTab?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ReferencesQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ReferencesQuery = {
  __typename?: 'Query';
  referenceLists?: {
    __typename?: 'ReferenceListEntityResponseCollection';
    data: Array<{
      __typename?: 'ReferenceListEntity';
      attributes?: {
        __typename?: 'ReferenceList';
        slug_cs?: string | null;
        slug_en?: string | null;
        slug_de?: string | null;
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        content?: Array<
          | {
              __typename?: 'ComponentSharedLargeImageSection';
              title?: string | null;
              anchor?: string | null;
              description?: string | null;
              button?: {
                __typename?: 'ComponentUiButton';
                id: string;
                label: string;
                path?: string | null;
                openInNewTab?: boolean | null;
              } | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentSharedTextImageSection';
              anchor?: string | null;
              title?: string | null;
              text?: any | null;
              image?: {
                __typename?: 'ComponentUiImage';
                id: string;
                alt: string;
                img_desktop: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                };
                img_mobile?: {
                  __typename?: 'UploadFileEntityResponse';
                  data?: {
                    __typename?: 'UploadFileEntity';
                    id?: string | null;
                    attributes?: {
                      __typename?: 'UploadFile';
                      url: string;
                      alternativeText?: string | null;
                      blurhash?: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              IconSpec?: Array<{
                __typename?: 'ComponentSharedIconSpec';
                text?: string | null;
                icon?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoImagesGrid';
              anchor?: string | null;
              title?: string | null;
              imagesGridItems?: Array<{
                __typename?: 'ComponentSharedImagesGridItem';
                title?: string | null;
                description?: string | null;
                image?: {
                  __typename?: 'ComponentUiImage';
                  id: string;
                  alt: string;
                  img_desktop: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  img_mobile?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentSharedTwoVideosGrid';
              anchor?: string | null;
              title?: string | null;
              videosGridItems?: Array<{
                __typename?: 'ComponentSharedVideosGridItem';
                title?: string | null;
                description?: string | null;
                video?: {
                  __typename?: 'ComponentUiVideo';
                  poster_alt?: string | null;
                  poster: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  };
                  mobilePoster?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  video?: {
                    __typename?: 'UploadFileEntityResponse';
                    data?: {
                      __typename?: 'UploadFileEntity';
                      id?: string | null;
                      attributes?: {
                        __typename?: 'UploadFile';
                        url: string;
                        alternativeText?: string | null;
                        blurhash?: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ServiceSeoQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceSeoQuery = {
  __typename?: 'Query';
  service?: {
    __typename?: 'ServiceEntityResponse';
    data?: {
      __typename?: 'ServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Service';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ServiceHeroVideoQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceHeroVideoQuery = {
  __typename?: 'Query';
  service?: {
    __typename?: 'ServiceEntityResponse';
    data?: {
      __typename?: 'ServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Service';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ServiceHeroImageQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceHeroImageQuery = {
  __typename?: 'Query';
  service?: {
    __typename?: 'ServiceEntityResponse';
    data?: {
      __typename?: 'ServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Service';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ServiceContentFragment = {
  __typename?: 'Service';
  content?: Array<
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type ServiceContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type ServiceContentMainQuery = {
  __typename?: 'Query';
  service?: {
    __typename?: 'ServiceEntityResponse';
    data?: {
      __typename?: 'ServiceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Service';
        content?: Array<
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type FormServiceWebexQueryVariables = Exact<{ [key: string]: never }>;

export type FormServiceWebexQuery = {
  __typename?: 'Query';
  webexIntegration?: {
    __typename?: 'WebexIntegrationEntityResponse';
    data?: {
      __typename?: 'WebexIntegrationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'WebexIntegration';
        slug?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MetaSocialFragment = {
  __typename?: 'ComponentSharedMetaSocial';
  id: string;
  title: string;
  description: string;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SeoFragment = {
  __typename?: 'ComponentSharedSeo';
  id: string;
  canonicalURL?: string | null;
  keywords?: string | null;
  metaDescription: string;
  metaRobots?: string | null;
  metaTitle: string;
  metaViewport?: string | null;
  structuredData?: any | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    id: string;
    title: string;
    description: string;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ProductSectionFragment = {
  __typename?: 'ComponentSharedProducts';
  title?: string | null;
  anchor?: string | null;
  products?: {
    __typename?: 'ProductRelationResponseCollection';
    data: Array<{
      __typename?: 'ProductEntity';
      attributes?: {
        __typename?: 'Product';
        slug_cs?: string | null;
        slug_de?: string | null;
        slug_en?: string | null;
        preview?: {
          __typename?: 'ComponentSharedPreview';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ReferenceSectionFragment = {
  __typename?: 'ComponentSharedReferences';
  title?: string | null;
  anchor?: string | null;
  reference_lists?: {
    __typename?: 'ReferenceListRelationResponseCollection';
    data: Array<{
      __typename?: 'ReferenceListEntity';
      attributes?: {
        __typename?: 'ReferenceList';
        slug_cs?: string | null;
        slug_de?: string | null;
        slug_en?: string | null;
        preview?: {
          __typename?: 'ComponentSharedPreview';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ManufacturerSectionFragment = {
  __typename?: 'ComponentSharedManufacturers';
  title?: string | null;
  anchor?: string | null;
  manufacturer_lists?: {
    __typename?: 'ManufacturerListRelationResponseCollection';
    data: Array<{
      __typename?: 'ManufacturerListEntity';
      attributes?: {
        __typename?: 'ManufacturerList';
        slug_cs?: string | null;
        slug_de?: string | null;
        slug_en?: string | null;
        preview?: {
          __typename?: 'ComponentSharedPreview';
          title?: string | null;
          description?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type LogoCarouselSectionFragment = {
  __typename?: 'ComponentSharedLogoCarousel';
  title?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
  item?: Array<{
    __typename?: 'ComponentSharedLogoCarouselItem';
    link?: {
      __typename?: 'ComponentUiLink';
      label: string;
      path: string;
      openInNewTab?: boolean | null;
    } | null;
    image?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SectionContentFragment = {
  __typename?: 'ComponentSharedSectionContent';
  title?: string | null;
  subtitle?: string | null;
  content?: { __typename?: 'ComponentUiTextContent'; text?: any | null } | null;
};

export type TextItemFragment = {
  __typename?: 'ComponentSharedTextItem';
  title?: string | null;
  description?: string | null;
};

export type OurTeamItemFragment = {
  __typename?: 'ComponentSharedOurTeamItem';
  name?: string | null;
  position?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type WorkflowSectionFragment = {
  __typename?: 'ComponentSharedWorkflow';
  anchor?: string | null;
  sectionContent?: {
    __typename?: 'ComponentSharedSectionContent';
    title?: string | null;
    subtitle?: string | null;
    content?: {
      __typename?: 'ComponentUiTextContent';
      text?: any | null;
    } | null;
  } | null;
  item?: Array<{
    __typename?: 'ComponentSharedTextItem';
    title?: string | null;
    description?: string | null;
  } | null> | null;
};

export type OurTeamSectionFragment = {
  __typename?: 'ComponentSharedOurTeam';
  anchor?: string | null;
  sectionContent?: {
    __typename?: 'ComponentSharedSectionContent';
    title?: string | null;
    subtitle?: string | null;
    content?: {
      __typename?: 'ComponentUiTextContent';
      text?: any | null;
    } | null;
  } | null;
  item?: Array<{
    __typename?: 'ComponentSharedOurTeamItem';
    name?: string | null;
    position?: string | null;
    image?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type LargeImageSectionFragment = {
  __typename?: 'ComponentSharedLargeImageSection';
  title?: string | null;
  anchor?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type VideosGridItemFragment = {
  __typename?: 'ComponentSharedVideosGridItem';
  title?: string | null;
  description?: string | null;
  video?: {
    __typename?: 'ComponentUiVideo';
    poster_alt?: string | null;
    poster: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ImagesGridItemFragment = {
  __typename?: 'ComponentSharedImagesGridItem';
  title?: string | null;
  description?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TwoVideosGridFragment = {
  __typename?: 'ComponentSharedTwoVideosGrid';
  anchor?: string | null;
  title?: string | null;
  videosGridItems?: Array<{
    __typename?: 'ComponentSharedVideosGridItem';
    title?: string | null;
    description?: string | null;
    video?: {
      __typename?: 'ComponentUiVideo';
      poster_alt?: string | null;
      poster: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      mobilePoster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type TwoImagesGridFragment = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  anchor?: string | null;
  title?: string | null;
  imagesGridItems?: Array<{
    __typename?: 'ComponentSharedImagesGridItem';
    title?: string | null;
    description?: string | null;
    image?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IconSpecFragment = {
  __typename?: 'ComponentSharedIconSpec';
  text?: string | null;
  icon?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TextImageSectionFragment = {
  __typename?: 'ComponentSharedTextImageSection';
  anchor?: string | null;
  title?: string | null;
  text?: any | null;
  image?: {
    __typename?: 'ComponentUiImage';
    id: string;
    alt: string;
    img_desktop: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    };
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  IconSpec?: Array<{
    __typename?: 'ComponentSharedIconSpec';
    text?: string | null;
    icon?: {
      __typename?: 'ComponentUiImage';
      id: string;
      alt: string;
      img_desktop: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      };
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SocialResponsibilitySeoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SocialResponsibilitySeoQuery = {
  __typename?: 'Query';
  socialResponsibility?: {
    __typename?: 'SocialResponsibilityEntityResponse';
    data?: {
      __typename?: 'SocialResponsibilityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SocialResponsibility';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SocialResponsibilityHeroVideoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SocialResponsibilityHeroVideoQuery = {
  __typename?: 'Query';
  socialResponsibility?: {
    __typename?: 'SocialResponsibilityEntityResponse';
    data?: {
      __typename?: 'SocialResponsibilityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SocialResponsibility';
        heroVideoSection?: {
          __typename?: 'ComponentSharedHeroVideoSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroVideo?: {
            __typename?: 'ComponentUiVideo';
            poster_alt?: string | null;
            poster: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SocialResponsibilityHeroImageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SocialResponsibilityHeroImageQuery = {
  __typename?: 'Query';
  socialResponsibility?: {
    __typename?: 'SocialResponsibilityEntityResponse';
    data?: {
      __typename?: 'SocialResponsibilityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SocialResponsibility';
        heroImageSection?: {
          __typename?: 'ComponentSharedHeroImageSection';
          id: string;
          title?: string | null;
          subtitle?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            id: string;
            alt: string;
            img_desktop: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            };
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SocialResponsibilityContentFragment = {
  __typename?: 'SocialResponsibility';
  content?: Array<
    | {
        __typename?: 'ComponentUiImage';
        id: string;
        alt: string;
        img_desktop: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        };
        img_mobile?: {
          __typename?: 'UploadFileEntityResponse';
          data?: {
            __typename?: 'UploadFileEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UploadFile';
              url: string;
              alternativeText?: string | null;
              blurhash?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: 'ComponentUiTextContent'; text?: any | null }
    | { __typename?: 'Error' }
    | null
  > | null;
};

export type SocialResponsibilityContentMainQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SocialResponsibilityContentMainQuery = {
  __typename?: 'Query';
  socialResponsibility?: {
    __typename?: 'SocialResponsibilityEntityResponse';
    data?: {
      __typename?: 'SocialResponsibilityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SocialResponsibility';
        content?: Array<
          | {
              __typename?: 'ComponentUiImage';
              id: string;
              alt: string;
              img_desktop: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              };
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: 'ComponentUiTextContent'; text?: any | null }
          | { __typename?: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        blurhash
      }
    }
  }
`;
export const ImageFragmentDoc = gql`
  fragment image on ComponentUiImage {
    id
    alt
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const SectionContentFragmentDoc = gql`
  fragment sectionContent on ComponentSharedSectionContent {
    title
    subtitle
    content {
      text
    }
  }
`;
export const OurTeamItemFragmentDoc = gql`
  fragment ourTeamItem on ComponentSharedOurTeamItem {
    name
    position
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const OurTeamSectionFragmentDoc = gql`
  fragment ourTeamSection on ComponentSharedOurTeam {
    anchor
    sectionContent {
      ...sectionContent
    }
    item {
      ...ourTeamItem
    }
  }
  ${SectionContentFragmentDoc}
  ${OurTeamItemFragmentDoc}
`;
export const AboutUsContentFragmentDoc = gql`
  fragment aboutUsContent on Company {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
      ... on ComponentSharedOurTeam {
        ...ourTeamSection
      }
    }
  }
  ${ImageFragmentDoc}
  ${OurTeamSectionFragmentDoc}
`;
export const UploadDocumentFragmentDoc = gql`
  fragment uploadDocument on UploadFileRelationResponseCollection {
    data {
      id
      attributes {
        url
        size
        blurhash
      }
    }
  }
`;
export const ButtonFragmentDoc = gql`
  fragment button on ComponentUiButton {
    id
    label
    path
    openInNewTab
  }
`;
export const LinkFragmentDoc = gql`
  fragment link on ComponentUiLink {
    label
    path
    openInNewTab
  }
`;
export const LogoCarouselSectionFragmentDoc = gql`
  fragment logoCarouselSection on ComponentSharedLogoCarousel {
    title
    anchor
    button {
      ...button
    }
    item {
      link {
        ...link
      }
      image {
        ...image
      }
    }
  }
  ${ButtonFragmentDoc}
  ${LinkFragmentDoc}
  ${ImageFragmentDoc}
`;
export const TextItemFragmentDoc = gql`
  fragment textItem on ComponentSharedTextItem {
    title
    description
  }
`;
export const WorkflowSectionFragmentDoc = gql`
  fragment workflowSection on ComponentSharedWorkflow {
    anchor
    sectionContent {
      ...sectionContent
    }
    item {
      ...textItem
    }
  }
  ${SectionContentFragmentDoc}
  ${TextItemFragmentDoc}
`;
export const HomeContentFragmentDoc = gql`
  fragment homeContent on Home {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
      ... on ComponentSharedLogoCarousel {
        ...logoCarouselSection
      }
      ... on ComponentSharedWorkflow {
        ...workflowSection
      }
    }
  }
  ${ImageFragmentDoc}
  ${LogoCarouselSectionFragmentDoc}
  ${WorkflowSectionFragmentDoc}
`;
export const NavigationMenuItemFragmentDoc = gql`
  fragment navigationMenuItem on ComponentNavigationMenuItem {
    label
    link {
      ...link
    }
    subLink {
      ...link
    }
    isButton
  }
  ${LinkFragmentDoc}
`;
export const ManufacturerListPreviewFragmentDoc = gql`
  fragment manufacturerListPreview on ComponentSharedPreview {
    title
    description
    image {
      ...image
    }
    button {
      ...button
    }
  }
  ${ImageFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const HeroImageSectionFragmentDoc = gql`
  fragment heroImageSection on ComponentSharedHeroImageSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroImage {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const VideoFragmentDoc = gql`
  fragment video on ComponentUiVideo {
    poster {
      ...uploadFile
    }
    mobilePoster {
      ...uploadFile
    }
    poster_alt
    video {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroVideoSectionFragmentDoc = gql`
  fragment heroVideoSection on ComponentSharedHeroVideoSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroVideo {
      ...video
    }
  }
  ${ButtonFragmentDoc}
  ${VideoFragmentDoc}
`;
export const LargeImageSectionFragmentDoc = gql`
  fragment largeImageSection on ComponentSharedLargeImageSection {
    title
    anchor
    description
    button {
      ...button
    }
    image {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const VideosGridItemFragmentDoc = gql`
  fragment videosGridItem on ComponentSharedVideosGridItem {
    title
    description
    video {
      ...video
    }
  }
  ${VideoFragmentDoc}
`;
export const TwoVideosGridFragmentDoc = gql`
  fragment twoVideosGrid on ComponentSharedTwoVideosGrid {
    anchor
    title
    videosGridItems {
      ...videosGridItem
    }
  }
  ${VideosGridItemFragmentDoc}
`;
export const ImagesGridItemFragmentDoc = gql`
  fragment imagesGridItem on ComponentSharedImagesGridItem {
    title
    description
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const TwoImagesGridFragmentDoc = gql`
  fragment twoImagesGrid on ComponentSharedTwoImagesGrid {
    anchor
    title
    imagesGridItems {
      ...imagesGridItem
    }
  }
  ${ImagesGridItemFragmentDoc}
`;
export const IconSpecFragmentDoc = gql`
  fragment iconSpec on ComponentSharedIconSpec {
    text
    icon {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const TextImageSectionFragmentDoc = gql`
  fragment textImageSection on ComponentSharedTextImageSection {
    anchor
    title
    text
    image {
      ...image
    }
    IconSpec {
      ...iconSpec
    }
  }
  ${ImageFragmentDoc}
  ${IconSpecFragmentDoc}
`;
export const MetaSocialFragmentDoc = gql`
  fragment metaSocial on ComponentSharedMetaSocial {
    id
    title
    description
    socialNetwork
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment seo on ComponentSharedSeo {
    id
    canonicalURL
    keywords
    metaDescription
    metaImage {
      ...uploadFile
    }
    metaRobots
    metaSocial {
      ...metaSocial
    }
    metaTitle
    metaViewport
    structuredData
  }
  ${UploadFileFragmentDoc}
  ${MetaSocialFragmentDoc}
`;
export const ManufacturerListFragmentDoc = gql`
  fragment manufacturerList on ManufacturerList {
    slug_cs
    slug_en
    slug_de
    heroImageSection {
      ...heroImageSection
    }
    heroVideoSection {
      ...heroVideoSection
    }
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
      ... on ComponentSharedLargeImageSection {
        ...largeImageSection
      }
      ... on ComponentSharedTwoVideosGrid {
        ...twoVideosGrid
      }
      ... on ComponentSharedTwoImagesGrid {
        ...twoImagesGrid
      }
      ... on ComponentSharedTextImageSection {
        ...textImageSection
      }
    }
    seo {
      ...seo
    }
  }
  ${HeroImageSectionFragmentDoc}
  ${HeroVideoSectionFragmentDoc}
  ${ImageFragmentDoc}
  ${LargeImageSectionFragmentDoc}
  ${TwoVideosGridFragmentDoc}
  ${TwoImagesGridFragmentDoc}
  ${TextImageSectionFragmentDoc}
  ${SeoFragmentDoc}
`;
export const ManufacturerPartnerContentFragmentDoc = gql`
  fragment manufacturerPartnerContent on ManufacturersAndPartner {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const PolicyFieldsFragmentDoc = gql`
  fragment policyFields on CookiePolicyCookiePolicyDynamicZone {
    ... on ComponentPoliciesMainHeading {
      text
    }
    ... on ComponentPoliciesTitleTextContent {
      text
    }
    ... on ComponentPoliciesTableTextContent {
      richText
      tableHead {
        headingItem
      }
      tableRow {
        table_rows {
          data {
            attributes {
              rows {
                item
              }
            }
          }
        }
      }
    }
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    slug_cs
    slug_en
    slug_de
    heroImageSection {
      ...heroImageSection
    }
    heroVideoSection {
      ...heroVideoSection
    }
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
      ... on ComponentSharedLargeImageSection {
        ...largeImageSection
      }
      ... on ComponentSharedTwoVideosGrid {
        ...twoVideosGrid
      }
      ... on ComponentSharedTwoImagesGrid {
        ...twoImagesGrid
      }
      ... on ComponentSharedTextImageSection {
        ...textImageSection
      }
    }
    seo {
      ...seo
    }
  }
  ${HeroImageSectionFragmentDoc}
  ${HeroVideoSectionFragmentDoc}
  ${ImageFragmentDoc}
  ${LargeImageSectionFragmentDoc}
  ${TwoVideosGridFragmentDoc}
  ${TwoImagesGridFragmentDoc}
  ${TextImageSectionFragmentDoc}
  ${SeoFragmentDoc}
`;
export const ProductServiceFragmentDoc = gql`
  fragment productService on Product {
    slug_cs
    slug_en
    slug_de
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const ReferenceContentFragmentDoc = gql`
  fragment referenceContent on Reference {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const ReferenceListPreviewFragmentDoc = gql`
  fragment referenceListPreview on ComponentSharedPreview {
    title
    description
    image {
      ...image
    }
    button {
      ...button
    }
  }
  ${ImageFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const ReferenceListFragmentDoc = gql`
  fragment referenceList on ReferenceList {
    slug_cs
    slug_en
    slug_de
    heroImageSection {
      ...heroImageSection
    }
    heroVideoSection {
      ...heroVideoSection
    }
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
      ... on ComponentSharedLargeImageSection {
        ...largeImageSection
      }
      ... on ComponentSharedTwoVideosGrid {
        ...twoVideosGrid
      }
      ... on ComponentSharedTwoImagesGrid {
        ...twoImagesGrid
      }
      ... on ComponentSharedTextImageSection {
        ...textImageSection
      }
    }
    seo {
      ...seo
    }
  }
  ${HeroImageSectionFragmentDoc}
  ${HeroVideoSectionFragmentDoc}
  ${ImageFragmentDoc}
  ${LargeImageSectionFragmentDoc}
  ${TwoVideosGridFragmentDoc}
  ${TwoImagesGridFragmentDoc}
  ${TextImageSectionFragmentDoc}
  ${SeoFragmentDoc}
`;
export const ServiceContentFragmentDoc = gql`
  fragment serviceContent on Service {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const ProductPreviewFragmentDoc = gql`
  fragment productPreview on ComponentSharedPreview {
    title
    description
    image {
      ...image
    }
    button {
      ...button
    }
  }
  ${ImageFragmentDoc}
  ${ButtonFragmentDoc}
`;
export const ProductSectionFragmentDoc = gql`
  fragment productSection on ComponentSharedProducts {
    title
    anchor
    products {
      data {
        attributes {
          slug_cs
          slug_de
          slug_en
          preview {
            ...productPreview
          }
        }
      }
    }
  }
  ${ProductPreviewFragmentDoc}
`;
export const ReferenceSectionFragmentDoc = gql`
  fragment referenceSection on ComponentSharedReferences {
    title
    anchor
    reference_lists {
      data {
        attributes {
          slug_cs
          slug_de
          slug_en
          preview {
            ...productPreview
          }
        }
      }
    }
  }
  ${ProductPreviewFragmentDoc}
`;
export const ManufacturerSectionFragmentDoc = gql`
  fragment manufacturerSection on ComponentSharedManufacturers {
    title
    anchor
    manufacturer_lists {
      data {
        attributes {
          slug_cs
          slug_de
          slug_en
          preview {
            ...productPreview
          }
        }
      }
    }
  }
  ${ProductPreviewFragmentDoc}
`;
export const SocialResponsibilityContentFragmentDoc = gql`
  fragment socialResponsibilityContent on SocialResponsibility {
    content {
      ... on ComponentUiTextContent {
        text
      }
      ... on ComponentUiImage {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const AboutUsSeoDocument = gql`
  query aboutUsSeo {
    company {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useAboutUsSeoQuery__
 *
 * To run a query within a React component, call `useAboutUsSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutUsSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutUsSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutUsSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutUsSeoQuery,
    AboutUsSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutUsSeoQuery, AboutUsSeoQueryVariables>(
    AboutUsSeoDocument,
    options,
  );
}
export function useAboutUsSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutUsSeoQuery,
    AboutUsSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AboutUsSeoQuery, AboutUsSeoQueryVariables>(
    AboutUsSeoDocument,
    options,
  );
}
export function useAboutUsSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutUsSeoQuery,
        AboutUsSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AboutUsSeoQuery, AboutUsSeoQueryVariables>(
    AboutUsSeoDocument,
    options,
  );
}
export type AboutUsSeoQueryHookResult = ReturnType<typeof useAboutUsSeoQuery>;
export type AboutUsSeoLazyQueryHookResult = ReturnType<
  typeof useAboutUsSeoLazyQuery
>;
export type AboutUsSeoSuspenseQueryHookResult = ReturnType<
  typeof useAboutUsSeoSuspenseQuery
>;
export type AboutUsSeoQueryResult = Apollo.QueryResult<
  AboutUsSeoQuery,
  AboutUsSeoQueryVariables
>;
export const AboutUsHeroVideoDocument = gql`
  query aboutUsHeroVideo {
    company {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useAboutUsHeroVideoQuery__
 *
 * To run a query within a React component, call `useAboutUsHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutUsHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutUsHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutUsHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutUsHeroVideoQuery,
    AboutUsHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutUsHeroVideoQuery, AboutUsHeroVideoQueryVariables>(
    AboutUsHeroVideoDocument,
    options,
  );
}
export function useAboutUsHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutUsHeroVideoQuery,
    AboutUsHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutUsHeroVideoQuery,
    AboutUsHeroVideoQueryVariables
  >(AboutUsHeroVideoDocument, options);
}
export function useAboutUsHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutUsHeroVideoQuery,
        AboutUsHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutUsHeroVideoQuery,
    AboutUsHeroVideoQueryVariables
  >(AboutUsHeroVideoDocument, options);
}
export type AboutUsHeroVideoQueryHookResult = ReturnType<
  typeof useAboutUsHeroVideoQuery
>;
export type AboutUsHeroVideoLazyQueryHookResult = ReturnType<
  typeof useAboutUsHeroVideoLazyQuery
>;
export type AboutUsHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useAboutUsHeroVideoSuspenseQuery
>;
export type AboutUsHeroVideoQueryResult = Apollo.QueryResult<
  AboutUsHeroVideoQuery,
  AboutUsHeroVideoQueryVariables
>;
export const AboutUsHeroImageDocument = gql`
  query aboutUsHeroImage {
    company {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useAboutUsHeroImageQuery__
 *
 * To run a query within a React component, call `useAboutUsHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutUsHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutUsHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutUsHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutUsHeroImageQuery,
    AboutUsHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutUsHeroImageQuery, AboutUsHeroImageQueryVariables>(
    AboutUsHeroImageDocument,
    options,
  );
}
export function useAboutUsHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutUsHeroImageQuery,
    AboutUsHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutUsHeroImageQuery,
    AboutUsHeroImageQueryVariables
  >(AboutUsHeroImageDocument, options);
}
export function useAboutUsHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutUsHeroImageQuery,
        AboutUsHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutUsHeroImageQuery,
    AboutUsHeroImageQueryVariables
  >(AboutUsHeroImageDocument, options);
}
export type AboutUsHeroImageQueryHookResult = ReturnType<
  typeof useAboutUsHeroImageQuery
>;
export type AboutUsHeroImageLazyQueryHookResult = ReturnType<
  typeof useAboutUsHeroImageLazyQuery
>;
export type AboutUsHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useAboutUsHeroImageSuspenseQuery
>;
export type AboutUsHeroImageQueryResult = Apollo.QueryResult<
  AboutUsHeroImageQuery,
  AboutUsHeroImageQueryVariables
>;
export const AboutUsContentMainDocument = gql`
  query aboutUsContentMain($locale: I18NLocaleCode) {
    company(locale: $locale) {
      data {
        id
        attributes {
          ...aboutUsContent
        }
      }
    }
  }
  ${AboutUsContentFragmentDoc}
`;

/**
 * __useAboutUsContentMainQuery__
 *
 * To run a query within a React component, call `useAboutUsContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutUsContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutUsContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutUsContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutUsContentMainQuery,
    AboutUsContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AboutUsContentMainQuery,
    AboutUsContentMainQueryVariables
  >(AboutUsContentMainDocument, options);
}
export function useAboutUsContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutUsContentMainQuery,
    AboutUsContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutUsContentMainQuery,
    AboutUsContentMainQueryVariables
  >(AboutUsContentMainDocument, options);
}
export function useAboutUsContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutUsContentMainQuery,
        AboutUsContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutUsContentMainQuery,
    AboutUsContentMainQueryVariables
  >(AboutUsContentMainDocument, options);
}
export type AboutUsContentMainQueryHookResult = ReturnType<
  typeof useAboutUsContentMainQuery
>;
export type AboutUsContentMainLazyQueryHookResult = ReturnType<
  typeof useAboutUsContentMainLazyQuery
>;
export type AboutUsContentMainSuspenseQueryHookResult = ReturnType<
  typeof useAboutUsContentMainSuspenseQuery
>;
export type AboutUsContentMainQueryResult = Apollo.QueryResult<
  AboutUsContentMainQuery,
  AboutUsContentMainQueryVariables
>;
export const SendContactFormDocument = gql`
  mutation SendContactForm($data: ContactFormDataInput!) {
    sendContactForm(data: $data) {
      success
    }
  }
`;
export type SendContactFormMutationFn = Apollo.MutationFunction<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;

/**
 * __useSendContactFormMutation__
 *
 * To run a mutation, you first call `useSendContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactFormMutation, { data, loading, error }] = useSendContactFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >(SendContactFormDocument, options);
}
export type SendContactFormMutationHookResult = ReturnType<
  typeof useSendContactFormMutation
>;
export type SendContactFormMutationResult =
  Apollo.MutationResult<SendContactFormMutation>;
export type SendContactFormMutationOptions = Apollo.BaseMutationOptions<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;
export const SendServiceFormDocument = gql`
  mutation SendServiceForm($data: ServiceFormDataInput!) {
    sendServiceForm(data: $data) {
      success
    }
  }
`;
export type SendServiceFormMutationFn = Apollo.MutationFunction<
  SendServiceFormMutation,
  SendServiceFormMutationVariables
>;

/**
 * __useSendServiceFormMutation__
 *
 * To run a mutation, you first call `useSendServiceFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendServiceFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendServiceFormMutation, { data, loading, error }] = useSendServiceFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendServiceFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendServiceFormMutation,
    SendServiceFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendServiceFormMutation,
    SendServiceFormMutationVariables
  >(SendServiceFormDocument, options);
}
export type SendServiceFormMutationHookResult = ReturnType<
  typeof useSendServiceFormMutation
>;
export type SendServiceFormMutationResult =
  Apollo.MutationResult<SendServiceFormMutation>;
export type SendServiceFormMutationOptions = Apollo.BaseMutationOptions<
  SendServiceFormMutation,
  SendServiceFormMutationVariables
>;
export const HomeSeoDocument = gql`
  query homeSeo($locale: I18NLocaleCode) {
    home(locale: $locale) {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useHomeSeoQuery__
 *
 * To run a query within a React component, call `useHomeSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSeoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHomeSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export function useHomeSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HomeSeoQuery, HomeSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HomeSeoQuery, HomeSeoQueryVariables>(
    HomeSeoDocument,
    options,
  );
}
export type HomeSeoQueryHookResult = ReturnType<typeof useHomeSeoQuery>;
export type HomeSeoLazyQueryHookResult = ReturnType<typeof useHomeSeoLazyQuery>;
export type HomeSeoSuspenseQueryHookResult = ReturnType<
  typeof useHomeSeoSuspenseQuery
>;
export type HomeSeoQueryResult = Apollo.QueryResult<
  HomeSeoQuery,
  HomeSeoQueryVariables
>;
export const HomeHeroVideoDocument = gql`
  query HomeHeroVideo {
    home {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useHomeHeroVideoQuery__
 *
 * To run a query within a React component, call `useHomeHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeHeroVideoQuery,
    HomeHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeHeroVideoQuery, HomeHeroVideoQueryVariables>(
    HomeHeroVideoDocument,
    options,
  );
}
export function useHomeHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeroVideoQuery,
    HomeHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeHeroVideoQuery, HomeHeroVideoQueryVariables>(
    HomeHeroVideoDocument,
    options,
  );
}
export function useHomeHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeHeroVideoQuery,
        HomeHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeHeroVideoQuery,
    HomeHeroVideoQueryVariables
  >(HomeHeroVideoDocument, options);
}
export type HomeHeroVideoQueryHookResult = ReturnType<
  typeof useHomeHeroVideoQuery
>;
export type HomeHeroVideoLazyQueryHookResult = ReturnType<
  typeof useHomeHeroVideoLazyQuery
>;
export type HomeHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeroVideoSuspenseQuery
>;
export type HomeHeroVideoQueryResult = Apollo.QueryResult<
  HomeHeroVideoQuery,
  HomeHeroVideoQueryVariables
>;
export const HomeHeroImageDocument = gql`
  query HomeHeroImage {
    home {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useHomeHeroImageQuery__
 *
 * To run a query within a React component, call `useHomeHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeHeroImageQuery,
    HomeHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeHeroImageQuery, HomeHeroImageQueryVariables>(
    HomeHeroImageDocument,
    options,
  );
}
export function useHomeHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeHeroImageQuery,
    HomeHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HomeHeroImageQuery, HomeHeroImageQueryVariables>(
    HomeHeroImageDocument,
    options,
  );
}
export function useHomeHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeHeroImageQuery,
        HomeHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeHeroImageQuery,
    HomeHeroImageQueryVariables
  >(HomeHeroImageDocument, options);
}
export type HomeHeroImageQueryHookResult = ReturnType<
  typeof useHomeHeroImageQuery
>;
export type HomeHeroImageLazyQueryHookResult = ReturnType<
  typeof useHomeHeroImageLazyQuery
>;
export type HomeHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useHomeHeroImageSuspenseQuery
>;
export type HomeHeroImageQueryResult = Apollo.QueryResult<
  HomeHeroImageQuery,
  HomeHeroImageQueryVariables
>;
export const HomeContentMainDocument = gql`
  query homeContentMain($locale: I18NLocaleCode) {
    home(locale: $locale) {
      data {
        id
        attributes {
          ...homeContent
        }
      }
    }
  }
  ${HomeContentFragmentDoc}
`;

/**
 * __useHomeContentMainQuery__
 *
 * To run a query within a React component, call `useHomeContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useHomeContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomeContentMainQuery,
    HomeContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomeContentMainQuery, HomeContentMainQueryVariables>(
    HomeContentMainDocument,
    options,
  );
}
export function useHomeContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomeContentMainQuery,
    HomeContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomeContentMainQuery,
    HomeContentMainQueryVariables
  >(HomeContentMainDocument, options);
}
export function useHomeContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HomeContentMainQuery,
        HomeContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HomeContentMainQuery,
    HomeContentMainQueryVariables
  >(HomeContentMainDocument, options);
}
export type HomeContentMainQueryHookResult = ReturnType<
  typeof useHomeContentMainQuery
>;
export type HomeContentMainLazyQueryHookResult = ReturnType<
  typeof useHomeContentMainLazyQuery
>;
export type HomeContentMainSuspenseQueryHookResult = ReturnType<
  typeof useHomeContentMainSuspenseQuery
>;
export type HomeContentMainQueryResult = Apollo.QueryResult<
  HomeContentMainQuery,
  HomeContentMainQueryVariables
>;
export const FormContactWebexDocument = gql`
  query FormContactWebex {
    webexIntegration {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

/**
 * __useFormContactWebexQuery__
 *
 * To run a query within a React component, call `useFormContactWebexQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormContactWebexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormContactWebexQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormContactWebexQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormContactWebexQuery, FormContactWebexQueryVariables>(
    FormContactWebexDocument,
    options,
  );
}
export function useFormContactWebexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >(FormContactWebexDocument, options);
}
export function useFormContactWebexSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FormContactWebexQuery,
        FormContactWebexQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FormContactWebexQuery,
    FormContactWebexQueryVariables
  >(FormContactWebexDocument, options);
}
export type FormContactWebexQueryHookResult = ReturnType<
  typeof useFormContactWebexQuery
>;
export type FormContactWebexLazyQueryHookResult = ReturnType<
  typeof useFormContactWebexLazyQuery
>;
export type FormContactWebexSuspenseQueryHookResult = ReturnType<
  typeof useFormContactWebexSuspenseQuery
>;
export type FormContactWebexQueryResult = Apollo.QueryResult<
  FormContactWebexQuery,
  FormContactWebexQueryVariables
>;
export const FooterDocument = gql`
  query Footer {
    footer {
      data {
        id
        attributes {
          column {
            label
            link {
              ...link
            }
          }
          policies {
            label
            link {
              ...link
            }
          }
          contact {
            label
            phone {
              ...link
            }
            email {
              ...link
            }
          }
        }
      }
    }
  }
  ${LinkFragmentDoc}
`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterSuspenseQueryHookResult = ReturnType<
  typeof useFooterSuspenseQuery
>;
export type FooterQueryResult = Apollo.QueryResult<
  FooterQuery,
  FooterQueryVariables
>;
export const NavigationDocument = gql`
  query Navigation {
    navigation {
      data {
        attributes {
          menu {
            ...navigationMenuItem
          }
        }
      }
    }
  }
  ${NavigationMenuItemFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NavigationQuery,
        NavigationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<
  typeof useNavigationLazyQuery
>;
export type NavigationSuspenseQueryHookResult = ReturnType<
  typeof useNavigationSuspenseQuery
>;
export type NavigationQueryResult = Apollo.QueryResult<
  NavigationQuery,
  NavigationQueryVariables
>;
export const ManufacturerListSectionDocument = gql`
  query manufacturerListSection {
    manufacturersAndPartner {
      data {
        attributes {
          manufacturers {
            ...manufacturerSection
          }
        }
      }
    }
  }
  ${ManufacturerSectionFragmentDoc}
`;

/**
 * __useManufacturerListSectionQuery__
 *
 * To run a query within a React component, call `useManufacturerListSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerListSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerListSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturerListSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerListSectionQuery,
    ManufacturerListSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerListSectionQuery,
    ManufacturerListSectionQueryVariables
  >(ManufacturerListSectionDocument, options);
}
export function useManufacturerListSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerListSectionQuery,
    ManufacturerListSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerListSectionQuery,
    ManufacturerListSectionQueryVariables
  >(ManufacturerListSectionDocument, options);
}
export function useManufacturerListSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerListSectionQuery,
        ManufacturerListSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerListSectionQuery,
    ManufacturerListSectionQueryVariables
  >(ManufacturerListSectionDocument, options);
}
export type ManufacturerListSectionQueryHookResult = ReturnType<
  typeof useManufacturerListSectionQuery
>;
export type ManufacturerListSectionLazyQueryHookResult = ReturnType<
  typeof useManufacturerListSectionLazyQuery
>;
export type ManufacturerListSectionSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerListSectionSuspenseQuery
>;
export type ManufacturerListSectionQueryResult = Apollo.QueryResult<
  ManufacturerListSectionQuery,
  ManufacturerListSectionQueryVariables
>;
export const ManufacturersDocument = gql`
  query manufacturers($slug: String, $limit: Int) {
    manufacturerLists(
      filters: { slug_cs: { eq: $slug } }
      pagination: { limit: $limit }
    ) {
      data {
        attributes {
          ...manufacturerList
        }
      }
    }
  }
  ${ManufacturerListFragmentDoc}
`;

/**
 * __useManufacturersQuery__
 *
 * To run a query within a React component, call `useManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useManufacturersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturersQuery,
    ManufacturersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManufacturersQuery, ManufacturersQueryVariables>(
    ManufacturersDocument,
    options,
  );
}
export function useManufacturersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturersQuery,
    ManufacturersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManufacturersQuery, ManufacturersQueryVariables>(
    ManufacturersDocument,
    options,
  );
}
export function useManufacturersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturersQuery,
        ManufacturersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturersQuery,
    ManufacturersQueryVariables
  >(ManufacturersDocument, options);
}
export type ManufacturersQueryHookResult = ReturnType<
  typeof useManufacturersQuery
>;
export type ManufacturersLazyQueryHookResult = ReturnType<
  typeof useManufacturersLazyQuery
>;
export type ManufacturersSuspenseQueryHookResult = ReturnType<
  typeof useManufacturersSuspenseQuery
>;
export type ManufacturersQueryResult = Apollo.QueryResult<
  ManufacturersQuery,
  ManufacturersQueryVariables
>;
export const ManufacturerPartnerSeoDocument = gql`
  query manufacturerPartnerSeo {
    manufacturersAndPartner {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useManufacturerPartnerSeoQuery__
 *
 * To run a query within a React component, call `useManufacturerPartnerSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerPartnerSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerPartnerSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturerPartnerSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerPartnerSeoQuery,
    ManufacturerPartnerSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerPartnerSeoQuery,
    ManufacturerPartnerSeoQueryVariables
  >(ManufacturerPartnerSeoDocument, options);
}
export function useManufacturerPartnerSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerPartnerSeoQuery,
    ManufacturerPartnerSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerPartnerSeoQuery,
    ManufacturerPartnerSeoQueryVariables
  >(ManufacturerPartnerSeoDocument, options);
}
export function useManufacturerPartnerSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerPartnerSeoQuery,
        ManufacturerPartnerSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerPartnerSeoQuery,
    ManufacturerPartnerSeoQueryVariables
  >(ManufacturerPartnerSeoDocument, options);
}
export type ManufacturerPartnerSeoQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSeoQuery
>;
export type ManufacturerPartnerSeoLazyQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSeoLazyQuery
>;
export type ManufacturerPartnerSeoSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSeoSuspenseQuery
>;
export type ManufacturerPartnerSeoQueryResult = Apollo.QueryResult<
  ManufacturerPartnerSeoQuery,
  ManufacturerPartnerSeoQueryVariables
>;
export const ManufacturerPartnerHeroVideoDocument = gql`
  query manufacturerPartnerHeroVideo {
    manufacturersAndPartner {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useManufacturerPartnerHeroVideoQuery__
 *
 * To run a query within a React component, call `useManufacturerPartnerHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerPartnerHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerPartnerHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturerPartnerHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerPartnerHeroVideoQuery,
    ManufacturerPartnerHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerPartnerHeroVideoQuery,
    ManufacturerPartnerHeroVideoQueryVariables
  >(ManufacturerPartnerHeroVideoDocument, options);
}
export function useManufacturerPartnerHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerPartnerHeroVideoQuery,
    ManufacturerPartnerHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerPartnerHeroVideoQuery,
    ManufacturerPartnerHeroVideoQueryVariables
  >(ManufacturerPartnerHeroVideoDocument, options);
}
export function useManufacturerPartnerHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerPartnerHeroVideoQuery,
        ManufacturerPartnerHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerPartnerHeroVideoQuery,
    ManufacturerPartnerHeroVideoQueryVariables
  >(ManufacturerPartnerHeroVideoDocument, options);
}
export type ManufacturerPartnerHeroVideoQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroVideoQuery
>;
export type ManufacturerPartnerHeroVideoLazyQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroVideoLazyQuery
>;
export type ManufacturerPartnerHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroVideoSuspenseQuery
>;
export type ManufacturerPartnerHeroVideoQueryResult = Apollo.QueryResult<
  ManufacturerPartnerHeroVideoQuery,
  ManufacturerPartnerHeroVideoQueryVariables
>;
export const ManufacturerPartnerHeroImageDocument = gql`
  query manufacturerPartnerHeroImage {
    manufacturersAndPartner {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useManufacturerPartnerHeroImageQuery__
 *
 * To run a query within a React component, call `useManufacturerPartnerHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerPartnerHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerPartnerHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturerPartnerHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerPartnerHeroImageQuery,
    ManufacturerPartnerHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerPartnerHeroImageQuery,
    ManufacturerPartnerHeroImageQueryVariables
  >(ManufacturerPartnerHeroImageDocument, options);
}
export function useManufacturerPartnerHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerPartnerHeroImageQuery,
    ManufacturerPartnerHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerPartnerHeroImageQuery,
    ManufacturerPartnerHeroImageQueryVariables
  >(ManufacturerPartnerHeroImageDocument, options);
}
export function useManufacturerPartnerHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerPartnerHeroImageQuery,
        ManufacturerPartnerHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerPartnerHeroImageQuery,
    ManufacturerPartnerHeroImageQueryVariables
  >(ManufacturerPartnerHeroImageDocument, options);
}
export type ManufacturerPartnerHeroImageQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroImageQuery
>;
export type ManufacturerPartnerHeroImageLazyQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroImageLazyQuery
>;
export type ManufacturerPartnerHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerPartnerHeroImageSuspenseQuery
>;
export type ManufacturerPartnerHeroImageQueryResult = Apollo.QueryResult<
  ManufacturerPartnerHeroImageQuery,
  ManufacturerPartnerHeroImageQueryVariables
>;
export const ManufacturerPartnerContentMainDocument = gql`
  query manufacturerPartnerContentMain($locale: I18NLocaleCode) {
    manufacturersAndPartner(locale: $locale) {
      data {
        id
        attributes {
          ...manufacturerPartnerContent
        }
      }
    }
  }
  ${ManufacturerPartnerContentFragmentDoc}
`;

/**
 * __useManufacturerPartnerContentMainQuery__
 *
 * To run a query within a React component, call `useManufacturerPartnerContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerPartnerContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerPartnerContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useManufacturerPartnerContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerPartnerContentMainQuery,
    ManufacturerPartnerContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerPartnerContentMainQuery,
    ManufacturerPartnerContentMainQueryVariables
  >(ManufacturerPartnerContentMainDocument, options);
}
export function useManufacturerPartnerContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerPartnerContentMainQuery,
    ManufacturerPartnerContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerPartnerContentMainQuery,
    ManufacturerPartnerContentMainQueryVariables
  >(ManufacturerPartnerContentMainDocument, options);
}
export function useManufacturerPartnerContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerPartnerContentMainQuery,
        ManufacturerPartnerContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerPartnerContentMainQuery,
    ManufacturerPartnerContentMainQueryVariables
  >(ManufacturerPartnerContentMainDocument, options);
}
export type ManufacturerPartnerContentMainQueryHookResult = ReturnType<
  typeof useManufacturerPartnerContentMainQuery
>;
export type ManufacturerPartnerContentMainLazyQueryHookResult = ReturnType<
  typeof useManufacturerPartnerContentMainLazyQuery
>;
export type ManufacturerPartnerContentMainSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerPartnerContentMainSuspenseQuery
>;
export type ManufacturerPartnerContentMainQueryResult = Apollo.QueryResult<
  ManufacturerPartnerContentMainQuery,
  ManufacturerPartnerContentMainQueryVariables
>;
export const ManufacturerPartnerSectionDocument = gql`
  query manufacturerPartnerSection {
    manufacturersAndPartner {
      data {
        attributes {
          manufacturers {
            ...manufacturerSection
          }
        }
      }
    }
  }
  ${ManufacturerSectionFragmentDoc}
`;

/**
 * __useManufacturerPartnerSectionQuery__
 *
 * To run a query within a React component, call `useManufacturerPartnerSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerPartnerSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerPartnerSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useManufacturerPartnerSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManufacturerPartnerSectionQuery,
    ManufacturerPartnerSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManufacturerPartnerSectionQuery,
    ManufacturerPartnerSectionQueryVariables
  >(ManufacturerPartnerSectionDocument, options);
}
export function useManufacturerPartnerSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManufacturerPartnerSectionQuery,
    ManufacturerPartnerSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManufacturerPartnerSectionQuery,
    ManufacturerPartnerSectionQueryVariables
  >(ManufacturerPartnerSectionDocument, options);
}
export function useManufacturerPartnerSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ManufacturerPartnerSectionQuery,
        ManufacturerPartnerSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ManufacturerPartnerSectionQuery,
    ManufacturerPartnerSectionQueryVariables
  >(ManufacturerPartnerSectionDocument, options);
}
export type ManufacturerPartnerSectionQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSectionQuery
>;
export type ManufacturerPartnerSectionLazyQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSectionLazyQuery
>;
export type ManufacturerPartnerSectionSuspenseQueryHookResult = ReturnType<
  typeof useManufacturerPartnerSectionSuspenseQuery
>;
export type ManufacturerPartnerSectionQueryResult = Apollo.QueryResult<
  ManufacturerPartnerSectionQuery,
  ManufacturerPartnerSectionQueryVariables
>;
export const CookiePolicyDocument = gql`
  query CookiePolicy($locale: I18NLocaleCode) {
    cookiePolicy(locale: $locale) {
      data {
        attributes {
          cookiePolicy {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useCookiePolicyQuery__
 *
 * To run a query within a React component, call `useCookiePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiePolicyQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiePolicyQuery,
        CookiePolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export type CookiePolicyQueryHookResult = ReturnType<
  typeof useCookiePolicyQuery
>;
export type CookiePolicyLazyQueryHookResult = ReturnType<
  typeof useCookiePolicyLazyQuery
>;
export type CookiePolicySuspenseQueryHookResult = ReturnType<
  typeof useCookiePolicySuspenseQuery
>;
export type CookiePolicyQueryResult = Apollo.QueryResult<
  CookiePolicyQuery,
  CookiePolicyQueryVariables
>;
export const CookiesSettingsDocument = gql`
  query CookiesSettings($locale: I18NLocaleCode) {
    cookieSetting(locale: $locale) {
      data {
        id
        attributes {
          actionButtonLabel
          necessaryCookiesHeading
          necessaryCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          optionalCookiesHeading
          optionalCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          title
          redirectButton {
            label
            path
          }
        }
      }
    }
  }
`;

/**
 * __useCookiesSettingsQuery__
 *
 * To run a query within a React component, call `useCookiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesSettingsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesSettingsQuery, CookiesSettingsQueryVariables>(
    CookiesSettingsDocument,
    options,
  );
}
export function useCookiesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export function useCookiesSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesSettingsQuery,
        CookiesSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export type CookiesSettingsQueryHookResult = ReturnType<
  typeof useCookiesSettingsQuery
>;
export type CookiesSettingsLazyQueryHookResult = ReturnType<
  typeof useCookiesSettingsLazyQuery
>;
export type CookiesSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCookiesSettingsSuspenseQuery
>;
export type CookiesSettingsQueryResult = Apollo.QueryResult<
  CookiesSettingsQuery,
  CookiesSettingsQueryVariables
>;
export const CookiesBarDocument = gql`
  query CookiesBar($locale: I18NLocaleCode) {
    cookiesBanner(locale: $locale) {
      data {
        id
        attributes {
          heading
          editButtonLabel
          declineButtonLabel
          acceptButtonLabel
          text
          extraLinks {
            ...button
          }
          toggleText {
            firstText
            secondText
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;

/**
 * __useCookiesBarQuery__
 *
 * To run a query within a React component, call `useCookiesBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesBarQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiesBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesBarQuery,
        CookiesBarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export type CookiesBarQueryHookResult = ReturnType<typeof useCookiesBarQuery>;
export type CookiesBarLazyQueryHookResult = ReturnType<
  typeof useCookiesBarLazyQuery
>;
export type CookiesBarSuspenseQueryHookResult = ReturnType<
  typeof useCookiesBarSuspenseQuery
>;
export type CookiesBarQueryResult = Apollo.QueryResult<
  CookiesBarQuery,
  CookiesBarQueryVariables
>;
export const ProductsSectionDocument = gql`
  query productsSection {
    productAndService {
      data {
        attributes {
          products {
            ...productSection
          }
        }
      }
    }
  }
  ${ProductSectionFragmentDoc}
`;

/**
 * __useProductsSectionQuery__
 *
 * To run a query within a React component, call `useProductsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsSectionQuery,
    ProductsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsSectionQuery, ProductsSectionQueryVariables>(
    ProductsSectionDocument,
    options,
  );
}
export function useProductsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsSectionQuery,
    ProductsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsSectionQuery,
    ProductsSectionQueryVariables
  >(ProductsSectionDocument, options);
}
export function useProductsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductsSectionQuery,
        ProductsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductsSectionQuery,
    ProductsSectionQueryVariables
  >(ProductsSectionDocument, options);
}
export type ProductsSectionQueryHookResult = ReturnType<
  typeof useProductsSectionQuery
>;
export type ProductsSectionLazyQueryHookResult = ReturnType<
  typeof useProductsSectionLazyQuery
>;
export type ProductsSectionSuspenseQueryHookResult = ReturnType<
  typeof useProductsSectionSuspenseQuery
>;
export type ProductsSectionQueryResult = Apollo.QueryResult<
  ProductsSectionQuery,
  ProductsSectionQueryVariables
>;
export const ProductsDocument = gql`
  query products($slug: String, $limit: Int) {
    products(
      filters: { slug_cs: { eq: $slug } }
      pagination: { limit: $limit }
    ) {
      data {
        attributes {
          ...product
        }
      }
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options,
  );
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options,
  );
}
export function useProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ProductsQuery, ProductsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options,
  );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>;
export type ProductsSuspenseQueryHookResult = ReturnType<
  typeof useProductsSuspenseQuery
>;
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>;
export const ProductServiceSeoDocument = gql`
  query productServiceSeo {
    productAndService {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useProductServiceSeoQuery__
 *
 * To run a query within a React component, call `useProductServiceSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductServiceSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductServiceSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductServiceSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductServiceSeoQuery,
    ProductServiceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductServiceSeoQuery,
    ProductServiceSeoQueryVariables
  >(ProductServiceSeoDocument, options);
}
export function useProductServiceSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductServiceSeoQuery,
    ProductServiceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductServiceSeoQuery,
    ProductServiceSeoQueryVariables
  >(ProductServiceSeoDocument, options);
}
export function useProductServiceSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductServiceSeoQuery,
        ProductServiceSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductServiceSeoQuery,
    ProductServiceSeoQueryVariables
  >(ProductServiceSeoDocument, options);
}
export type ProductServiceSeoQueryHookResult = ReturnType<
  typeof useProductServiceSeoQuery
>;
export type ProductServiceSeoLazyQueryHookResult = ReturnType<
  typeof useProductServiceSeoLazyQuery
>;
export type ProductServiceSeoSuspenseQueryHookResult = ReturnType<
  typeof useProductServiceSeoSuspenseQuery
>;
export type ProductServiceSeoQueryResult = Apollo.QueryResult<
  ProductServiceSeoQuery,
  ProductServiceSeoQueryVariables
>;
export const ProductAndServiceHeroVideoDocument = gql`
  query productAndServiceHeroVideo {
    productAndService {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useProductAndServiceHeroVideoQuery__
 *
 * To run a query within a React component, call `useProductAndServiceHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAndServiceHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAndServiceHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductAndServiceHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductAndServiceHeroVideoQuery,
    ProductAndServiceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductAndServiceHeroVideoQuery,
    ProductAndServiceHeroVideoQueryVariables
  >(ProductAndServiceHeroVideoDocument, options);
}
export function useProductAndServiceHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductAndServiceHeroVideoQuery,
    ProductAndServiceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductAndServiceHeroVideoQuery,
    ProductAndServiceHeroVideoQueryVariables
  >(ProductAndServiceHeroVideoDocument, options);
}
export function useProductAndServiceHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductAndServiceHeroVideoQuery,
        ProductAndServiceHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductAndServiceHeroVideoQuery,
    ProductAndServiceHeroVideoQueryVariables
  >(ProductAndServiceHeroVideoDocument, options);
}
export type ProductAndServiceHeroVideoQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroVideoQuery
>;
export type ProductAndServiceHeroVideoLazyQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroVideoLazyQuery
>;
export type ProductAndServiceHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroVideoSuspenseQuery
>;
export type ProductAndServiceHeroVideoQueryResult = Apollo.QueryResult<
  ProductAndServiceHeroVideoQuery,
  ProductAndServiceHeroVideoQueryVariables
>;
export const ProductAndServiceHeroImageDocument = gql`
  query productAndServiceHeroImage {
    productAndService {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useProductAndServiceHeroImageQuery__
 *
 * To run a query within a React component, call `useProductAndServiceHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAndServiceHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAndServiceHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductAndServiceHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductAndServiceHeroImageQuery,
    ProductAndServiceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductAndServiceHeroImageQuery,
    ProductAndServiceHeroImageQueryVariables
  >(ProductAndServiceHeroImageDocument, options);
}
export function useProductAndServiceHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductAndServiceHeroImageQuery,
    ProductAndServiceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductAndServiceHeroImageQuery,
    ProductAndServiceHeroImageQueryVariables
  >(ProductAndServiceHeroImageDocument, options);
}
export function useProductAndServiceHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductAndServiceHeroImageQuery,
        ProductAndServiceHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductAndServiceHeroImageQuery,
    ProductAndServiceHeroImageQueryVariables
  >(ProductAndServiceHeroImageDocument, options);
}
export type ProductAndServiceHeroImageQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroImageQuery
>;
export type ProductAndServiceHeroImageLazyQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroImageLazyQuery
>;
export type ProductAndServiceHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useProductAndServiceHeroImageSuspenseQuery
>;
export type ProductAndServiceHeroImageQueryResult = Apollo.QueryResult<
  ProductAndServiceHeroImageQuery,
  ProductAndServiceHeroImageQueryVariables
>;
export const ProductsServicesProductsSectionDocument = gql`
  query productsServicesProductsSection {
    productAndService {
      data {
        attributes {
          products {
            ...productSection
          }
        }
      }
    }
  }
  ${ProductSectionFragmentDoc}
`;

/**
 * __useProductsServicesProductsSectionQuery__
 *
 * To run a query within a React component, call `useProductsServicesProductsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsServicesProductsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsServicesProductsSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsServicesProductsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsServicesProductsSectionQuery,
    ProductsServicesProductsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsServicesProductsSectionQuery,
    ProductsServicesProductsSectionQueryVariables
  >(ProductsServicesProductsSectionDocument, options);
}
export function useProductsServicesProductsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsServicesProductsSectionQuery,
    ProductsServicesProductsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsServicesProductsSectionQuery,
    ProductsServicesProductsSectionQueryVariables
  >(ProductsServicesProductsSectionDocument, options);
}
export function useProductsServicesProductsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProductsServicesProductsSectionQuery,
        ProductsServicesProductsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProductsServicesProductsSectionQuery,
    ProductsServicesProductsSectionQueryVariables
  >(ProductsServicesProductsSectionDocument, options);
}
export type ProductsServicesProductsSectionQueryHookResult = ReturnType<
  typeof useProductsServicesProductsSectionQuery
>;
export type ProductsServicesProductsSectionLazyQueryHookResult = ReturnType<
  typeof useProductsServicesProductsSectionLazyQuery
>;
export type ProductsServicesProductsSectionSuspenseQueryHookResult = ReturnType<
  typeof useProductsServicesProductsSectionSuspenseQuery
>;
export type ProductsServicesProductsSectionQueryResult = Apollo.QueryResult<
  ProductsServicesProductsSectionQuery,
  ProductsServicesProductsSectionQueryVariables
>;
export const ReferenceSeoDocument = gql`
  query referenceSeo {
    reference {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useReferenceSeoQuery__
 *
 * To run a query within a React component, call `useReferenceSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceSeoQuery,
    ReferenceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferenceSeoQuery, ReferenceSeoQueryVariables>(
    ReferenceSeoDocument,
    options,
  );
}
export function useReferenceSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceSeoQuery,
    ReferenceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferenceSeoQuery, ReferenceSeoQueryVariables>(
    ReferenceSeoDocument,
    options,
  );
}
export function useReferenceSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceSeoQuery,
        ReferenceSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReferenceSeoQuery, ReferenceSeoQueryVariables>(
    ReferenceSeoDocument,
    options,
  );
}
export type ReferenceSeoQueryHookResult = ReturnType<
  typeof useReferenceSeoQuery
>;
export type ReferenceSeoLazyQueryHookResult = ReturnType<
  typeof useReferenceSeoLazyQuery
>;
export type ReferenceSeoSuspenseQueryHookResult = ReturnType<
  typeof useReferenceSeoSuspenseQuery
>;
export type ReferenceSeoQueryResult = Apollo.QueryResult<
  ReferenceSeoQuery,
  ReferenceSeoQueryVariables
>;
export const ReferenceHeroVideoDocument = gql`
  query referenceHeroVideo {
    reference {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useReferenceHeroVideoQuery__
 *
 * To run a query within a React component, call `useReferenceHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceHeroVideoQuery,
    ReferenceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReferenceHeroVideoQuery,
    ReferenceHeroVideoQueryVariables
  >(ReferenceHeroVideoDocument, options);
}
export function useReferenceHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceHeroVideoQuery,
    ReferenceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferenceHeroVideoQuery,
    ReferenceHeroVideoQueryVariables
  >(ReferenceHeroVideoDocument, options);
}
export function useReferenceHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceHeroVideoQuery,
        ReferenceHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferenceHeroVideoQuery,
    ReferenceHeroVideoQueryVariables
  >(ReferenceHeroVideoDocument, options);
}
export type ReferenceHeroVideoQueryHookResult = ReturnType<
  typeof useReferenceHeroVideoQuery
>;
export type ReferenceHeroVideoLazyQueryHookResult = ReturnType<
  typeof useReferenceHeroVideoLazyQuery
>;
export type ReferenceHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useReferenceHeroVideoSuspenseQuery
>;
export type ReferenceHeroVideoQueryResult = Apollo.QueryResult<
  ReferenceHeroVideoQuery,
  ReferenceHeroVideoQueryVariables
>;
export const ReferenceHeroImageDocument = gql`
  query referenceHeroImage {
    reference {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useReferenceHeroImageQuery__
 *
 * To run a query within a React component, call `useReferenceHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceHeroImageQuery,
    ReferenceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReferenceHeroImageQuery,
    ReferenceHeroImageQueryVariables
  >(ReferenceHeroImageDocument, options);
}
export function useReferenceHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceHeroImageQuery,
    ReferenceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferenceHeroImageQuery,
    ReferenceHeroImageQueryVariables
  >(ReferenceHeroImageDocument, options);
}
export function useReferenceHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceHeroImageQuery,
        ReferenceHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferenceHeroImageQuery,
    ReferenceHeroImageQueryVariables
  >(ReferenceHeroImageDocument, options);
}
export type ReferenceHeroImageQueryHookResult = ReturnType<
  typeof useReferenceHeroImageQuery
>;
export type ReferenceHeroImageLazyQueryHookResult = ReturnType<
  typeof useReferenceHeroImageLazyQuery
>;
export type ReferenceHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useReferenceHeroImageSuspenseQuery
>;
export type ReferenceHeroImageQueryResult = Apollo.QueryResult<
  ReferenceHeroImageQuery,
  ReferenceHeroImageQueryVariables
>;
export const ReferenceContentMainDocument = gql`
  query referenceContentMain($locale: I18NLocaleCode) {
    reference(locale: $locale) {
      data {
        id
        attributes {
          ...referenceContent
        }
      }
    }
  }
  ${ReferenceContentFragmentDoc}
`;

/**
 * __useReferenceContentMainQuery__
 *
 * To run a query within a React component, call `useReferenceContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useReferenceContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceContentMainQuery,
    ReferenceContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReferenceContentMainQuery,
    ReferenceContentMainQueryVariables
  >(ReferenceContentMainDocument, options);
}
export function useReferenceContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceContentMainQuery,
    ReferenceContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferenceContentMainQuery,
    ReferenceContentMainQueryVariables
  >(ReferenceContentMainDocument, options);
}
export function useReferenceContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceContentMainQuery,
        ReferenceContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferenceContentMainQuery,
    ReferenceContentMainQueryVariables
  >(ReferenceContentMainDocument, options);
}
export type ReferenceContentMainQueryHookResult = ReturnType<
  typeof useReferenceContentMainQuery
>;
export type ReferenceContentMainLazyQueryHookResult = ReturnType<
  typeof useReferenceContentMainLazyQuery
>;
export type ReferenceContentMainSuspenseQueryHookResult = ReturnType<
  typeof useReferenceContentMainSuspenseQuery
>;
export type ReferenceContentMainQueryResult = Apollo.QueryResult<
  ReferenceContentMainQuery,
  ReferenceContentMainQueryVariables
>;
export const ReferenceSectionDocument = gql`
  query referenceSection {
    reference {
      data {
        attributes {
          references {
            ...referenceSection
          }
        }
      }
    }
  }
  ${ReferenceSectionFragmentDoc}
`;

/**
 * __useReferenceSectionQuery__
 *
 * To run a query within a React component, call `useReferenceSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceSectionQuery,
    ReferenceSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferenceSectionQuery, ReferenceSectionQueryVariables>(
    ReferenceSectionDocument,
    options,
  );
}
export function useReferenceSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceSectionQuery,
    ReferenceSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferenceSectionQuery,
    ReferenceSectionQueryVariables
  >(ReferenceSectionDocument, options);
}
export function useReferenceSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceSectionQuery,
        ReferenceSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferenceSectionQuery,
    ReferenceSectionQueryVariables
  >(ReferenceSectionDocument, options);
}
export type ReferenceSectionQueryHookResult = ReturnType<
  typeof useReferenceSectionQuery
>;
export type ReferenceSectionLazyQueryHookResult = ReturnType<
  typeof useReferenceSectionLazyQuery
>;
export type ReferenceSectionSuspenseQueryHookResult = ReturnType<
  typeof useReferenceSectionSuspenseQuery
>;
export type ReferenceSectionQueryResult = Apollo.QueryResult<
  ReferenceSectionQuery,
  ReferenceSectionQueryVariables
>;
export const ReferenceListSectionDocument = gql`
  query referenceListSection {
    reference {
      data {
        attributes {
          references {
            ...referenceSection
          }
        }
      }
    }
  }
  ${ReferenceSectionFragmentDoc}
`;

/**
 * __useReferenceListSectionQuery__
 *
 * To run a query within a React component, call `useReferenceListSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceListSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceListSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceListSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferenceListSectionQuery,
    ReferenceListSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReferenceListSectionQuery,
    ReferenceListSectionQueryVariables
  >(ReferenceListSectionDocument, options);
}
export function useReferenceListSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferenceListSectionQuery,
    ReferenceListSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferenceListSectionQuery,
    ReferenceListSectionQueryVariables
  >(ReferenceListSectionDocument, options);
}
export function useReferenceListSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferenceListSectionQuery,
        ReferenceListSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferenceListSectionQuery,
    ReferenceListSectionQueryVariables
  >(ReferenceListSectionDocument, options);
}
export type ReferenceListSectionQueryHookResult = ReturnType<
  typeof useReferenceListSectionQuery
>;
export type ReferenceListSectionLazyQueryHookResult = ReturnType<
  typeof useReferenceListSectionLazyQuery
>;
export type ReferenceListSectionSuspenseQueryHookResult = ReturnType<
  typeof useReferenceListSectionSuspenseQuery
>;
export type ReferenceListSectionQueryResult = Apollo.QueryResult<
  ReferenceListSectionQuery,
  ReferenceListSectionQueryVariables
>;
export const ReferencesDocument = gql`
  query references($slug: String, $limit: Int) {
    referenceLists(
      filters: { slug_cs: { eq: $slug } }
      pagination: { limit: $limit }
    ) {
      data {
        attributes {
          ...referenceList
        }
      }
    }
  }
  ${ReferenceListFragmentDoc}
`;

/**
 * __useReferencesQuery__
 *
 * To run a query within a React component, call `useReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferencesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useReferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReferencesQuery,
    ReferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferencesQuery, ReferencesQueryVariables>(
    ReferencesDocument,
    options,
  );
}
export function useReferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferencesQuery,
    ReferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferencesQuery, ReferencesQueryVariables>(
    ReferencesDocument,
    options,
  );
}
export function useReferencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferencesQuery,
        ReferencesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReferencesQuery, ReferencesQueryVariables>(
    ReferencesDocument,
    options,
  );
}
export type ReferencesQueryHookResult = ReturnType<typeof useReferencesQuery>;
export type ReferencesLazyQueryHookResult = ReturnType<
  typeof useReferencesLazyQuery
>;
export type ReferencesSuspenseQueryHookResult = ReturnType<
  typeof useReferencesSuspenseQuery
>;
export type ReferencesQueryResult = Apollo.QueryResult<
  ReferencesQuery,
  ReferencesQueryVariables
>;
export const ServiceSeoDocument = gql`
  query serviceSeo {
    service {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useServiceSeoQuery__
 *
 * To run a query within a React component, call `useServiceSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceSeoQuery,
    ServiceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceSeoQuery, ServiceSeoQueryVariables>(
    ServiceSeoDocument,
    options,
  );
}
export function useServiceSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceSeoQuery,
    ServiceSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServiceSeoQuery, ServiceSeoQueryVariables>(
    ServiceSeoDocument,
    options,
  );
}
export function useServiceSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceSeoQuery,
        ServiceSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ServiceSeoQuery, ServiceSeoQueryVariables>(
    ServiceSeoDocument,
    options,
  );
}
export type ServiceSeoQueryHookResult = ReturnType<typeof useServiceSeoQuery>;
export type ServiceSeoLazyQueryHookResult = ReturnType<
  typeof useServiceSeoLazyQuery
>;
export type ServiceSeoSuspenseQueryHookResult = ReturnType<
  typeof useServiceSeoSuspenseQuery
>;
export type ServiceSeoQueryResult = Apollo.QueryResult<
  ServiceSeoQuery,
  ServiceSeoQueryVariables
>;
export const ServiceHeroVideoDocument = gql`
  query serviceHeroVideo {
    service {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useServiceHeroVideoQuery__
 *
 * To run a query within a React component, call `useServiceHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceHeroVideoQuery,
    ServiceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceHeroVideoQuery, ServiceHeroVideoQueryVariables>(
    ServiceHeroVideoDocument,
    options,
  );
}
export function useServiceHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceHeroVideoQuery,
    ServiceHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceHeroVideoQuery,
    ServiceHeroVideoQueryVariables
  >(ServiceHeroVideoDocument, options);
}
export function useServiceHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceHeroVideoQuery,
        ServiceHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceHeroVideoQuery,
    ServiceHeroVideoQueryVariables
  >(ServiceHeroVideoDocument, options);
}
export type ServiceHeroVideoQueryHookResult = ReturnType<
  typeof useServiceHeroVideoQuery
>;
export type ServiceHeroVideoLazyQueryHookResult = ReturnType<
  typeof useServiceHeroVideoLazyQuery
>;
export type ServiceHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useServiceHeroVideoSuspenseQuery
>;
export type ServiceHeroVideoQueryResult = Apollo.QueryResult<
  ServiceHeroVideoQuery,
  ServiceHeroVideoQueryVariables
>;
export const ServiceHeroImageDocument = gql`
  query serviceHeroImage {
    service {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useServiceHeroImageQuery__
 *
 * To run a query within a React component, call `useServiceHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceHeroImageQuery,
    ServiceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceHeroImageQuery, ServiceHeroImageQueryVariables>(
    ServiceHeroImageDocument,
    options,
  );
}
export function useServiceHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceHeroImageQuery,
    ServiceHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceHeroImageQuery,
    ServiceHeroImageQueryVariables
  >(ServiceHeroImageDocument, options);
}
export function useServiceHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceHeroImageQuery,
        ServiceHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceHeroImageQuery,
    ServiceHeroImageQueryVariables
  >(ServiceHeroImageDocument, options);
}
export type ServiceHeroImageQueryHookResult = ReturnType<
  typeof useServiceHeroImageQuery
>;
export type ServiceHeroImageLazyQueryHookResult = ReturnType<
  typeof useServiceHeroImageLazyQuery
>;
export type ServiceHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useServiceHeroImageSuspenseQuery
>;
export type ServiceHeroImageQueryResult = Apollo.QueryResult<
  ServiceHeroImageQuery,
  ServiceHeroImageQueryVariables
>;
export const ServiceContentMainDocument = gql`
  query serviceContentMain($locale: I18NLocaleCode) {
    service(locale: $locale) {
      data {
        id
        attributes {
          ...serviceContent
        }
      }
    }
  }
  ${ServiceContentFragmentDoc}
`;

/**
 * __useServiceContentMainQuery__
 *
 * To run a query within a React component, call `useServiceContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useServiceContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceContentMainQuery,
    ServiceContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ServiceContentMainQuery,
    ServiceContentMainQueryVariables
  >(ServiceContentMainDocument, options);
}
export function useServiceContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceContentMainQuery,
    ServiceContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceContentMainQuery,
    ServiceContentMainQueryVariables
  >(ServiceContentMainDocument, options);
}
export function useServiceContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceContentMainQuery,
        ServiceContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceContentMainQuery,
    ServiceContentMainQueryVariables
  >(ServiceContentMainDocument, options);
}
export type ServiceContentMainQueryHookResult = ReturnType<
  typeof useServiceContentMainQuery
>;
export type ServiceContentMainLazyQueryHookResult = ReturnType<
  typeof useServiceContentMainLazyQuery
>;
export type ServiceContentMainSuspenseQueryHookResult = ReturnType<
  typeof useServiceContentMainSuspenseQuery
>;
export type ServiceContentMainQueryResult = Apollo.QueryResult<
  ServiceContentMainQuery,
  ServiceContentMainQueryVariables
>;
export const FormServiceWebexDocument = gql`
  query FormServiceWebex {
    webexIntegration {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

/**
 * __useFormServiceWebexQuery__
 *
 * To run a query within a React component, call `useFormServiceWebexQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormServiceWebexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormServiceWebexQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormServiceWebexQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FormServiceWebexQuery,
    FormServiceWebexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormServiceWebexQuery, FormServiceWebexQueryVariables>(
    FormServiceWebexDocument,
    options,
  );
}
export function useFormServiceWebexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormServiceWebexQuery,
    FormServiceWebexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FormServiceWebexQuery,
    FormServiceWebexQueryVariables
  >(FormServiceWebexDocument, options);
}
export function useFormServiceWebexSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FormServiceWebexQuery,
        FormServiceWebexQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FormServiceWebexQuery,
    FormServiceWebexQueryVariables
  >(FormServiceWebexDocument, options);
}
export type FormServiceWebexQueryHookResult = ReturnType<
  typeof useFormServiceWebexQuery
>;
export type FormServiceWebexLazyQueryHookResult = ReturnType<
  typeof useFormServiceWebexLazyQuery
>;
export type FormServiceWebexSuspenseQueryHookResult = ReturnType<
  typeof useFormServiceWebexSuspenseQuery
>;
export type FormServiceWebexQueryResult = Apollo.QueryResult<
  FormServiceWebexQuery,
  FormServiceWebexQueryVariables
>;
export const SocialResponsibilitySeoDocument = gql`
  query socialResponsibilitySeo {
    socialResponsibility {
      data {
        id
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useSocialResponsibilitySeoQuery__
 *
 * To run a query within a React component, call `useSocialResponsibilitySeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialResponsibilitySeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialResponsibilitySeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialResponsibilitySeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialResponsibilitySeoQuery,
    SocialResponsibilitySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialResponsibilitySeoQuery,
    SocialResponsibilitySeoQueryVariables
  >(SocialResponsibilitySeoDocument, options);
}
export function useSocialResponsibilitySeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialResponsibilitySeoQuery,
    SocialResponsibilitySeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialResponsibilitySeoQuery,
    SocialResponsibilitySeoQueryVariables
  >(SocialResponsibilitySeoDocument, options);
}
export function useSocialResponsibilitySeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SocialResponsibilitySeoQuery,
        SocialResponsibilitySeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialResponsibilitySeoQuery,
    SocialResponsibilitySeoQueryVariables
  >(SocialResponsibilitySeoDocument, options);
}
export type SocialResponsibilitySeoQueryHookResult = ReturnType<
  typeof useSocialResponsibilitySeoQuery
>;
export type SocialResponsibilitySeoLazyQueryHookResult = ReturnType<
  typeof useSocialResponsibilitySeoLazyQuery
>;
export type SocialResponsibilitySeoSuspenseQueryHookResult = ReturnType<
  typeof useSocialResponsibilitySeoSuspenseQuery
>;
export type SocialResponsibilitySeoQueryResult = Apollo.QueryResult<
  SocialResponsibilitySeoQuery,
  SocialResponsibilitySeoQueryVariables
>;
export const SocialResponsibilityHeroVideoDocument = gql`
  query socialResponsibilityHeroVideo {
    socialResponsibility {
      data {
        id
        attributes {
          heroVideoSection {
            ...heroVideoSection
          }
        }
      }
    }
  }
  ${HeroVideoSectionFragmentDoc}
`;

/**
 * __useSocialResponsibilityHeroVideoQuery__
 *
 * To run a query within a React component, call `useSocialResponsibilityHeroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialResponsibilityHeroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialResponsibilityHeroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialResponsibilityHeroVideoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialResponsibilityHeroVideoQuery,
    SocialResponsibilityHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialResponsibilityHeroVideoQuery,
    SocialResponsibilityHeroVideoQueryVariables
  >(SocialResponsibilityHeroVideoDocument, options);
}
export function useSocialResponsibilityHeroVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialResponsibilityHeroVideoQuery,
    SocialResponsibilityHeroVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialResponsibilityHeroVideoQuery,
    SocialResponsibilityHeroVideoQueryVariables
  >(SocialResponsibilityHeroVideoDocument, options);
}
export function useSocialResponsibilityHeroVideoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SocialResponsibilityHeroVideoQuery,
        SocialResponsibilityHeroVideoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialResponsibilityHeroVideoQuery,
    SocialResponsibilityHeroVideoQueryVariables
  >(SocialResponsibilityHeroVideoDocument, options);
}
export type SocialResponsibilityHeroVideoQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroVideoQuery
>;
export type SocialResponsibilityHeroVideoLazyQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroVideoLazyQuery
>;
export type SocialResponsibilityHeroVideoSuspenseQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroVideoSuspenseQuery
>;
export type SocialResponsibilityHeroVideoQueryResult = Apollo.QueryResult<
  SocialResponsibilityHeroVideoQuery,
  SocialResponsibilityHeroVideoQueryVariables
>;
export const SocialResponsibilityHeroImageDocument = gql`
  query socialResponsibilityHeroImage {
    socialResponsibility {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useSocialResponsibilityHeroImageQuery__
 *
 * To run a query within a React component, call `useSocialResponsibilityHeroImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialResponsibilityHeroImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialResponsibilityHeroImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSocialResponsibilityHeroImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialResponsibilityHeroImageQuery,
    SocialResponsibilityHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialResponsibilityHeroImageQuery,
    SocialResponsibilityHeroImageQueryVariables
  >(SocialResponsibilityHeroImageDocument, options);
}
export function useSocialResponsibilityHeroImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialResponsibilityHeroImageQuery,
    SocialResponsibilityHeroImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialResponsibilityHeroImageQuery,
    SocialResponsibilityHeroImageQueryVariables
  >(SocialResponsibilityHeroImageDocument, options);
}
export function useSocialResponsibilityHeroImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SocialResponsibilityHeroImageQuery,
        SocialResponsibilityHeroImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialResponsibilityHeroImageQuery,
    SocialResponsibilityHeroImageQueryVariables
  >(SocialResponsibilityHeroImageDocument, options);
}
export type SocialResponsibilityHeroImageQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroImageQuery
>;
export type SocialResponsibilityHeroImageLazyQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroImageLazyQuery
>;
export type SocialResponsibilityHeroImageSuspenseQueryHookResult = ReturnType<
  typeof useSocialResponsibilityHeroImageSuspenseQuery
>;
export type SocialResponsibilityHeroImageQueryResult = Apollo.QueryResult<
  SocialResponsibilityHeroImageQuery,
  SocialResponsibilityHeroImageQueryVariables
>;
export const SocialResponsibilityContentMainDocument = gql`
  query socialResponsibilityContentMain($locale: I18NLocaleCode) {
    socialResponsibility(locale: $locale) {
      data {
        id
        attributes {
          ...socialResponsibilityContent
        }
      }
    }
  }
  ${SocialResponsibilityContentFragmentDoc}
`;

/**
 * __useSocialResponsibilityContentMainQuery__
 *
 * To run a query within a React component, call `useSocialResponsibilityContentMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialResponsibilityContentMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialResponsibilityContentMainQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSocialResponsibilityContentMainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SocialResponsibilityContentMainQuery,
    SocialResponsibilityContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SocialResponsibilityContentMainQuery,
    SocialResponsibilityContentMainQueryVariables
  >(SocialResponsibilityContentMainDocument, options);
}
export function useSocialResponsibilityContentMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SocialResponsibilityContentMainQuery,
    SocialResponsibilityContentMainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SocialResponsibilityContentMainQuery,
    SocialResponsibilityContentMainQueryVariables
  >(SocialResponsibilityContentMainDocument, options);
}
export function useSocialResponsibilityContentMainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SocialResponsibilityContentMainQuery,
        SocialResponsibilityContentMainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SocialResponsibilityContentMainQuery,
    SocialResponsibilityContentMainQueryVariables
  >(SocialResponsibilityContentMainDocument, options);
}
export type SocialResponsibilityContentMainQueryHookResult = ReturnType<
  typeof useSocialResponsibilityContentMainQuery
>;
export type SocialResponsibilityContentMainLazyQueryHookResult = ReturnType<
  typeof useSocialResponsibilityContentMainLazyQuery
>;
export type SocialResponsibilityContentMainSuspenseQueryHookResult = ReturnType<
  typeof useSocialResponsibilityContentMainSuspenseQuery
>;
export type SocialResponsibilityContentMainQueryResult = Apollo.QueryResult<
  SocialResponsibilityContentMainQuery,
  SocialResponsibilityContentMainQueryVariables
>;
