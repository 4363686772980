import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

import { useGlobalType } from '@/types/useGlobalType';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: string;
  className?: string;
  label?: string;
  useType?: useGlobalType;
}

const Button = ({
  children,
  className,
  type,
  ariaLabel,
  onClick,
  useType = 'regular',
}: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        'rounded-full block w-full cursor-pointer border border-transparent px-8 py-4 text-center text-p uppercase transition-colors duration-200 hover:border-collapo-blue-75 disabled:bg-collapo-black-40 motion-reduce:transition-none',
        useType === 'regular' &&
          'bg-collapo-blue text-collapo-white hover:bg-collapo-blue-75',
        useType === 'form' &&
          ' bg-collapo-white text-collapo-black hover:bg-collapo-white',
        className,
      )}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};
export default Button;
