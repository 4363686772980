import clsx from 'clsx';
import { AnchorHTMLAttributes } from 'react';

import { useWindowSize } from '@/hooks/useWindowSize';
import { useGlobalType } from '@/types/useGlobalType';

export interface ButtonLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  ariaLabel?: string;
  className?: string;
  openInNewTab?: boolean;
  isTextLink?: boolean;
  useType?: useGlobalType;
}

const ButtonLink = ({
  children,
  href,
  className,
  ariaLabel,
  download,
  openInNewTab,
  isTextLink,
  useType = 'regular',
}: ButtonLinkProps) => {
  const { width } = useWindowSize();

  return (
    <a
      href={href}
      className={clsx(
        'block w-full cursor-pointer border border-transparent text-p transition-colors duration-300 hover:border-collapo-blue-75 disabled:bg-collapo-black-40 motion-reduce:transition-none',
        isTextLink
          ? clsx(
              useType === 'regular' &&
                'text-collapo-blue hover:text-collapo-blue-75',
              useType === 'form' &&
                ' bg-collapo-white text-collapo-black hover:bg-collapo-white',
            )
          : clsx(
              'px-8 py-4 text-center text-buttons uppercase',
              useType === 'regular' &&
                'bg-collapo-blue text-collapo-white hover:bg-collapo-blue-75',
              useType === 'form' &&
                ' bg-collapo-white text-collapo-black hover:bg-collapo-white',
            ),
        className,
      )}
      aria-label={ariaLabel}
      download={download}
      target={width && width > 1024 && openInNewTab ? '_blank' : '_self'}
    >
      {children}
    </a>
  );
};
export default ButtonLink;
