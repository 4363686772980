import clsx from 'clsx';
import { Fragment, ReactNode } from 'react';

export type TextType = 'paragraph' | 'heading' | 'list';

export type ChildrenType = {
  type: 'text' | 'link' | 'list-item';
  text?: string;
  bold?: boolean;
  italic?: boolean;
  url?: string;
  children?: ChildrenType[];
};

export type FormatType = 'ordered' | 'unordered';

export type RichTextProps = {
  type: TextType;
  children: ChildrenType[];
  format?: FormatType;
  level?: number;
};

type Props = {
  items: RichTextProps[];
  parentIndex: number;
  parentSection?: string;
  isCookieBar?: boolean;
  isProducePage?: boolean;
};

const getHeadingTag = (level: number): keyof JSX.IntrinsicElements => {
  const headingTags: { [key: number]: keyof JSX.IntrinsicElements } = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  };
  return headingTags[level] || 'h3';
};

const getHeadingClass = (
  tag: keyof JSX.IntrinsicElements,
  isProducePage?: boolean,
): string => {
  const headingClasses: { [key in keyof JSX.IntrinsicElements]?: string } = {
    h1: 'text-h1',
    h2: `text-h3 ${isProducePage && '!mt-10'}`,
    h3: `text-h3 ${isProducePage && '!mt-10'}`,
    h4: 'text-h4',
    h5: 'text-h5',
    h6: 'text-h6',
  };
  return headingClasses[tag] || 'text-h3';
};

const renderText = (
  child: ChildrenType,
  parentIndex: number,
  parentSection?: string,
): ReactNode => (
  <span
    key={`renderText-richText-${parentSection}-${parentIndex}`}
    className={clsx(child.bold && 'font-semi-bold', child.italic && 'italic')}
  >
    {child.text}
  </span>
);

const renderLink = (
  child: ChildrenType,
  parentIndex: number,
  parentSection?: string,
): ReactNode => (
  <a
    key={`renderLink-richText-${parentSection}-${parentIndex}`}
    target="__blank"
    href={child.url}
    className={clsx(
      'text-collapo-blue transition-colors duration-200 hover:text-collapo-blue-75 group-hover:text-collapo-blue-75 motion-reduce:transition-none',
      child.bold && 'font-semi-bold',
      child.italic && 'italic',
    )}
  >
    {child.children?.map((child) =>
      renderText(child, parentIndex, parentSection),
    )}
  </a>
);

const renderChildren = (
  children: ChildrenType[],
  parentIndex: number,
  parentSection?: string,
): ReactNode[] => {
  const isLinkParagraph = children.some((child) => child.type === 'link');

  return children.map((child, index) => (
    <Fragment
      key={`renderChildren-richText-${parentSection}-${parentIndex}-${index}`}
    >
      {child?.text?.length === 0 && !isLinkParagraph ? (
        <br />
      ) : (
        renderText(child, parentIndex, parentSection)
      )}
      {child.type === 'link' && renderLink(child, parentIndex, parentSection)}
    </Fragment>
  ));
};

const renderParagraph = (
  item: RichTextProps,
  parentIndex: number,
  index: number,
  parentSection?: string,
  isCookieBar?: boolean,
): ReactNode => {
  return (
    <Fragment
      key={`renderParagraph-richText-${parentSection}-${parentIndex}-${index}`}
    >
      <p
        className={clsx(
          isCookieBar
            ? 'text-p-small text-collapo-black sm:line-clamp-none'
            : 'text-p',
        )}
      >
        {renderChildren(item.children, parentIndex, parentSection)}
      </p>
    </Fragment>
  );
};

const renderHeading = (
  item: RichTextProps,
  parentIndex: number,
  index: number,
  parentSection?: string,
  isProducePage?: boolean,
): ReactNode => {
  const HeadingComponent = getHeadingTag(item.level || 3);
  const headingClass = getHeadingClass(HeadingComponent, isProducePage);
  return item.children.map((child, index) => (
    <HeadingComponent
      key={`renderHeading-richText-${parentSection}-${parentIndex}-${index}`}
      className={clsx(
        headingClass,
        child.bold && 'font-semi-bold',
        child.italic && 'italic',
      )}
    >
      {child.text}
    </HeadingComponent>
  ));
};

const renderList = (
  item: RichTextProps,
  parentIndex: number,
  index: number,
  parentSection?: string,
  isProducePage?: boolean,
): ReactNode => {
  const ListComponent = item.format === 'unordered' ? 'ul' : 'ol';
  const listClass =
    item.format === 'unordered'
      ? clsx(
          'mt-[22px] list-outside list-disc',
          isProducePage ? 'space-y-3 pl-4' : 'pl-[26px]',
        )
      : 'list-outside list-decimal space-y-2 pl-[15px]';

  return (
    <ListComponent
      className={listClass}
      key={`renderList-richText-${parentSection}-${parentIndex}-${index}`}
    >
      {item.children.map((child, index) => (
        <li
          className="text-p-small sm:text-p"
          key={`renderList-richText-${parentSection}-${parentIndex}-${index}`}
        >
          {renderChildren(child.children || [], parentIndex, parentSection)}
        </li>
      ))}
    </ListComponent>
  );
};

const RichText = ({
  items,
  isCookieBar,
  isProducePage,
  parentSection,
  parentIndex,
}: Props): ReactNode => (
  <Fragment>
    {items.map((item, index) => {
      switch (item.type) {
        case 'paragraph':
          return renderParagraph(
            item,
            parentIndex,
            index,
            parentSection,
            isCookieBar,
          );
        case 'heading':
          return renderHeading(
            item,
            parentIndex,
            index,
            parentSection,
            isProducePage,
          );
        case 'list':
          return renderList(
            item,
            parentIndex,
            index,
            parentSection,
            isProducePage,
          );
        default:
          return null;
      }
    })}
  </Fragment>
);

export default RichText;
