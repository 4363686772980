import { CookiesSettingsHeaderProps } from '@/components/CookiesSettings/CookiesSettings';
import Picture from '@/components/Picture/Picture';
import { projectConfig } from '@/constants/projectConfig';

export const CookiesSettingsHeader = ({
  heading,
  closeModal,
}: CookiesSettingsHeaderProps) => {
  return (
    <header className="relative grid grid-cols-2 items-center justify-between gap-y-6 py-4 md:grid-cols-3">
      <div className="h-5 w-[160px] sm:h-15">
        <Picture
          imgSrc={`${projectConfig.cloudinaryProjectName}/collapo-logo`}
          alt="Collapo logo"
          classNameImage="w-auto h-15"
          width={160}
          height={60}
          enabledLazyLoading={false}
        />
      </div>
      <span className="order-3 col-span-2 text-center text-h3 text-collapo-black md:order-none md:col-span-1">
        {heading}
      </span>
      <button
        className="flex size-8 items-center justify-center justify-self-end"
        onClick={closeModal}
        aria-label="Close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.39844 2.46875L20.9633 21.0336"
            stroke="black"
            strokeWidth="1.8"
            strokeLinejoin="round"
          />
          <path
            d="M2.39844 20.9648L20.9633 2.39998"
            stroke="black"
            strokeWidth="1.8"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </header>
  );
};
